import React from "react";

import { ThemeProvider } from "@mui/material/styles";

import { theme } from "theme.main";
import Router from "./Router";
import "./App.css";

const App = () => (
  <ThemeProvider theme={theme}>
    <Router />
  </ThemeProvider>
);

export default App;
