import { IMAGE_FILE_FORMATS, VIDEO_FILE_FORMATS } from "constants/index";

/**
 * turns this into this => Turns This Into This
 *
 * @param str string to title case
 */
export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

/**
 * Replaces special characters in a string so that it may be used as part of a 'pretty' URL.
 *
 * parameterize("Dwight K. Shrute") => "dwight-k-shrute"
 * parameterize("Dwight K. Shrute", "_") => "dwight_k_shrute"
 * parameterize("Dwight K. Shrute", "_", true) => "Dwight_K_Shrute"
 *
 * @param word base word to parameterize
 * @param separator delimeter to substitute spaces in URL
 * @param preserveCase boolean - to preserve the case
 */
export const parameterize = (
  word: string,
  separator: string = "-",
  preserveCase: boolean = false,
) => {
  const parameterizedWord = word.replace(/[^a-z0-9\-_]+/gi, separator);
  return preserveCase ? parameterizedWord : parameterizedWord.toLowerCase();
};

/**
 * Given current url, get given query param
 *
 * getQueryValue("?query=param&hello=aloha", "hello") => "aloha"
 *
 * @param params string - params to search through
 * @param queryKey string - specific key to grab in query
 */
export const getQueryValue = (params: string, queryKey: string) => {
  const pageParam = params
    .replace("?", "")
    .split("&")
    .find((param) => param.includes(queryKey));
  return pageParam?.split("=")[1];
};

/**
 * Copies string to clipboard
 *
 * only works on chrome
 * @params copiedText
 */
export const copyToClipboard = (copiedText: string) => {
  navigator.clipboard.writeText(copiedText).then(() => {
    // const message = "Successfully copied password";
    // const description = ``;
    // TODO: use snackbar instead
    // notification.success({ message, description });
  });
};

/**
 * Pluralizing words, ex: duck
 * 1 duck
 * 2 ducks
 * 0 ducks
 *
 * @param count number of items
 * @param word string - base word
 * @param suffix string - how to make it plural (sometimes can be 'es')
 */
export const pluralize = (
  count: number,
  word: string,
  suffix: string = "s",
) => {
  return `${count} ${word}${count === 1 ? "" : suffix}`;
};

/**
 * Returns false if image, true if video
 *
 * @example "dog-playing.mp4" => true
 * @example "dog-playing.mov" => true
 * @example "dog-playing.png" => false
 * @example "dog-playing.jpg" => false
 *
 * @param source image or video src
 */
export const checkMediaFormat = (source: string | undefined) => {
  if (!source) return null;

  if (VIDEO_FILE_FORMATS.some((file_format) => source.includes(file_format)))
    return true;
  if (IMAGE_FILE_FORMATS.some((file_format) => source.includes(file_format)))
    return false;
  throw new Error("Source file is not image or video");
};

/**
 * Takes in address elements, and makes it easy to read format
 *
 * @returns 94 Alvin Sloan Ave
 * Washington, NJ
 *
 * @returns 57 Kenmare St, Apt 13
 * New York, NY 10012
 */
export const formattedAddress = (
  address: string,
  city: string,
  state: string,
  zip: string,
) => `${address}\n ${city}, ${state} ${zip}`;
