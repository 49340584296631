import React, { useRef, useState } from "react";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import { US_STATES, ZIPCODE_REGEX } from "constants/index";
import { Flex } from "elements/common";
import { Form } from "elements/forms";
import { formattedAddress } from "utils/strings";

type ShippingForm = {
  address: string;
  city: string;
  state: string;
  zipcode: string;
};

type NewShippingAddressProps = {
  setCloseForm: (x: boolean) => void;
  setShippingAddress: (x: string) => void; // TODO: recoil
};

type InputProps = {
  register: UseFormRegister<ShippingForm>;
  input_error?: FieldError;
};

const AddressInput = ({ register, input_error }: InputProps) => (
  <FormControl error={Boolean(input_error)} margin="dense">
    <InputLabel htmlFor="address-input" required>
      Address
    </InputLabel>
    <OutlinedInput
      id="address-input"
      label="Address"
      placeholder="100 Imaginary St, Apt 13"
      {...register("address", {
        required: true,
      })}
    />
    {input_error?.type === "required" && (
      <FormHelperText id="component-error-text">
        Please add address
      </FormHelperText>
    )}
  </FormControl>
);

const CityInput = ({ register, input_error }: InputProps) => (
  <FormControl error={Boolean(input_error)} margin="dense">
    <InputLabel htmlFor="city-input" required>
      City
    </InputLabel>
    <OutlinedInput
      id="city-input"
      placeholder="New York"
      label="City"
      {...register("city", {
        required: true,
      })}
    />
    {input_error?.type === "required" && (
      <FormHelperText id="component-error-text">
        A city is required
      </FormHelperText>
    )}
  </FormControl>
);

const StateInputDropdown = ({ register, input_error }: InputProps) => {
  const [state, setState] = useState("");
  const searchTimeout = useRef<number>();
  const searchString = useRef<string>("");

  /** throttles the setting of the search string from the Input */
  const getSearchString: (x: string) => string = (character: string) => {
    if (typeof searchTimeout.current === "number") {
      window.clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = window.setTimeout(() => {
      searchString.current = "";
    }, 500);

    searchString.current += character;
    return searchString.current;
  };

  return (
    <FormControl
      error={Boolean(input_error)}
      margin="dense"
      onKeyDown={(e) => {
        const state_input = getSearchString(e.key);
        const newStateSelection = US_STATES.filter((state) =>
          state.toLowerCase().startsWith(state_input.toLowerCase()),
        )[0];

        if (!!newStateSelection) setState(newStateSelection);
      }}
      sx={{
        marginRight: ["1rem", "1rem", 0, "1rem"],
        minWidth: "175px",
      }}
    >
      <InputLabel htmlFor="state-outlined" required>
        State
      </InputLabel>
      <Select
        labelId="state-selection-label"
        id="state-selection"
        value={state}
        label="State"
        {...register("state", {
          required: true,
          onChange: (e) => setState(e.target.value),
        })}
      >
        {US_STATES.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
      {input_error?.type === "required" && (
        <FormHelperText id="component-error-text">
          Please select state
        </FormHelperText>
      )}
    </FormControl>
  );
};

const ZipcodeInput = ({ register, input_error }: InputProps) => (
  <FormControl error={Boolean(input_error)} margin="dense">
    <InputLabel htmlFor="zipcode-input" required>
      Zipcode
    </InputLabel>
    <OutlinedInput
      id="zipcode-input"
      placeholder="10012"
      label="Zipcode"
      {...register("zipcode", {
        required: true,
        pattern: ZIPCODE_REGEX,
      })}
    />
    {input_error?.type === "required" && (
      <FormHelperText id="component-error-text">
        A zipcode is required
      </FormHelperText>
    )}
    {input_error?.type === "pattern" && (
      <FormHelperText id="component-error-text">
        Enter a valid zipcode
      </FormHelperText>
    )}
  </FormControl>
);

const NewShippingAddress = ({
  setCloseForm,
  setShippingAddress,
}: NewShippingAddressProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ShippingForm>({
    defaultValues: {
      address: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  const handleSaveAddress = ({
    address,
    city,
    state,
    zipcode,
  }: ShippingForm) => {
    setShippingAddress(formattedAddress(address, city, state, zipcode));
    setCloseForm(true);
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(handleSaveAddress)}>
      <AddressInput register={register} input_error={errors.address} />
      <CityInput register={register} input_error={errors.city} />

      <Flex flexDirection={["row", "row", "column", "row"]}>
        <StateInputDropdown register={register} input_error={errors.state} />
        <ZipcodeInput register={register} input_error={errors.zipcode} />
      </Flex>

      <Button
        variant="outlined"
        type="submit"
        sx={{ marginTop: "2rem", borderRadius: "50px" }}
      >
        Save Address
      </Button>
    </Form>
  );
};

export default NewShippingAddress;
