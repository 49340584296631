import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { MediaDisplay } from "components/lists";
import ImageBuilder from "components/image-builder";
import { FloatingFavIcon } from "components/cards/listing";
import { MainLayout as Layout } from "components/layout";
import { Flex } from "elements/common";
import { ListingDetailWrapper } from "elements/listing";
import { fakeListings } from "tests/fake-returns";
import { ListingImageProps, MediaType } from "types";
import Badge from "components/badge";
import { ListingType } from "types/api";
import { theme } from "theme.main";

const ListingImage = ({
  bookListing,
  selectedMedia,
  setSelectedMedia,
}: ListingImageProps) => {
  const handleBackButton = () => {
    if (!selectedMedia || !bookListing) return;

    const currIndex = bookListing.mediaList
      .map((e: MediaType) => e.key)
      .indexOf(selectedMedia.key);

    if (currIndex === 0) {
      setSelectedMedia(bookListing.mediaList[bookListing.mediaList.length - 1]);
      return;
    }

    setSelectedMedia(bookListing.mediaList[currIndex - 1]);
  };

  const handleForwardButton = () => {
    if (!selectedMedia || !bookListing) return;

    const currIndex = bookListing.mediaList
      .map((e) => e.key)
      .indexOf(selectedMedia.key);

    if (currIndex === bookListing.mediaList.length - 1) {
      setSelectedMedia(bookListing.mediaList[0]);
      return;
    }

    setSelectedMedia(bookListing.mediaList[currIndex + 1]);
  };

  return (
    <Flex
      width={["90vw", "300px"]}
      maxWidth="425px"
      alignItems="center"
      alignSelf={["center", "flex-start"]}
      position="relative"
      justifyContent={["center", "flex-start"]}
    >
      <IconButton
        onClick={handleBackButton}
        sx={{
          position: "absolute",
          left: 0,
          display: ["flex", "none"],
          background: theme.palette.common.white,
          boxShadow: 5,
          zIndex: 1,
          ":hover": { background: theme.palette.action.hover },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Flex position="relative" width="300px" height="300px">
        <FloatingFavIcon favorite={bookListing.favorite} disableAnimation />

        {selectedMedia && (
          <ImageBuilder
            className="main-image"
            key={selectedMedia.key}
            resourceType={selectedMedia.resource_type}
            fileName={selectedMedia.src}
            width={300}
          />
        )}
      </Flex>
      <IconButton
        onClick={handleForwardButton}
        sx={{
          position: "absolute",
          right: 0,
          display: ["flex", "none"],
          background: theme.palette.common.white,
          boxShadow: 5,
          zIndex: 1,
          ":hover": { background: theme.palette.action.hover },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Flex>
  );
};

const ListingDescription = ({ bookListing }: { bookListing: ListingType }) => (
  <Flex
    flexDirection="column"
    marginLeft={[0, 0, "2rem"]}
    marginTop={["2rem", "1rem", 0]}
    textAlign="left"
  >
    <Typography variant="h4" fontWeight={200}>
      ${bookListing.price}
    </Typography>
    {bookListing.discount && <Badge text={bookListing.discount} />}
    <Typography>Title: {bookListing.title}</Typography>
    <Typography>Material: Made with leather and cloth</Typography>
    <Typography>
      Color: It's made with green cloth and silver lining for the designs
    </Typography>
    <Typography>Dimensions: 30in x 50in x 25in</Typography>
    <Typography>Date Made: 03/03/2024</Typography>
    <Typography>
      Availabilty: 2 in stock now, available for back order
      {/* <Button variant="outlined">Request more </Button> */}
    </Typography>
  </Flex>
);

const LisitingActions = () => (
  <Flex
    justifyContent="space-between"
    alignSelf={["flex-start", "flex-start", "center"]}
    marginTop="2rem"
    flexDirection={"column"}
    width="350px"
  >
    <Button
      color="secondary"
      variant="outlined"
      sx={{
        borderRadius: "2rem",
        fontSize: "1.1em",
      }}
    >
      Add to Wishlist{" "}
    </Button>
    <Button
      color="secondary"
      variant="contained"
      size="large"
      startIcon={<ShoppingCartIcon />}
      sx={{
        borderRadius: "2rem",
        fontSize: "1.1em",
        borderWidth: "medium",
        marginTop: "1rem",
      }}
    >
      Add to Cart
    </Button>
  </Flex>
);

const DetailListing = () => {
  const [selectedMedia, setSelectedMedia] = useState<MediaType>();
  const [bookListing, setBookListing] = useState<ListingType>(); // TODO: maybe generic listing later
  const { listingID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // FOR TEST USE ONLY
    const listing = fakeListings.find((listing) => listing.key === listingID);
    if (!listing) navigate("/404");

    setBookListing(listing);
    setSelectedMedia(listing?.mediaList[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingID]);

  if (!bookListing) return null;

  return (
    <Layout bgColor={theme.palette.secondary.light}>
      <ListingDetailWrapper margin={["2rem", "3rem"]} justifyContent="center">
        <MediaDisplay
          selectMedia={setSelectedMedia}
          currentMediaKey={selectedMedia?.key || ""}
          mediaList={bookListing.mediaList}
        />

        <Flex
          marginLeft={[0, "3rem"]}
          alignItems="flex-start"
          flexDirection={["column", "column", "row"]}
        >
          <ListingImage
            selectedMedia={selectedMedia}
            bookListing={bookListing}
            setSelectedMedia={setSelectedMedia}
          />
          <Paper
            sx={{
              display: "flex",
              padding: "1rem",
              marginLeft: [0, 0, "1rem"],
              marginTop: ["1rem", "1rem", 0],
              flexDirection: ["column"],
            }}
          >
            <ListingDescription bookListing={bookListing} />

            <LisitingActions />
          </Paper>
        </Flex>
      </ListingDetailWrapper>
    </Layout>
  );
};

export default DetailListing;
