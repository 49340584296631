import React from "react";

import { Typography } from "@mui/material";

import { MainLayout as Layout, TitleLayout } from "components/layout";
import ImageBuilder from "components/image-builder";
import { Flex } from "elements/common";
import { AboutUsImageWrapper } from "elements/about-us";
import { theme } from "theme.main";

const AboutUs = () => (
  <Layout bgColor={theme.palette.primary.light}>
    <TitleLayout title="About Us" color={theme.palette.common.white}>
      <Flex
        flexDirection={["row"]}
        justifyContent={"space-around"}
        alignSelf={"center"}
        maxWidth={"1050px"}
        color={theme.palette.common.white}
      >
        <Flex flexDirection={"column"} textAlign="left" maxWidth={["85%"]}>
          <AboutUsImageWrapper
            marginTop="2rem"
            alignItems={"flex-start"}
            flexDirection={["column", "column", "row"]}
          >
            <Typography marginRight={[0, 0, "3rem"]}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              tincidunt erat eu molestie dictum. Nulla libero mi, euismod mattis
              lobortis in, auctor ut ex. In hac habitasse platea dictumst.
              Praesent eget orci vitae arcu vehicula tempor. Etiam et purus eu
              lacus maximus tempor. Duis nec auctor eros. Nam iaculis neque in
              porta dictum. Ut mauris dui, aliquam id consectetur ac, dapibus
              vel ipsum. Sed sed hendrerit lorem. Class aptent taciti sociosqu
              ad litora torquent per conubia nostra, per inceptos himenaeos.
              Etiam ut rutrum diam. Nullam pretium viverra tellus non malesuada.
              Integer non varius mauris. Suspendisse potenti.
            </Typography>

            <ImageBuilder
              fileName="cld-sample"
              width={500}
              resourceType="image"
            />
          </AboutUsImageWrapper>

          <Typography marginTop="2rem">
            Nestled in the heart of the quaint town, stood as a testament to the
            enduring love for the written word. The shop's exterior was adorned
            with ivy that climbed up the old brick walls, giving it a timeless
            charm. Inside, the air was filled with the rich scent of leather and
            paper, a comforting aroma that spoke of countless stories bound
            together with care and craftsmanship. The shelves were lined with
            tools of the trade: spools of thread in every color, stacks of
            premium paper, and an array of embossing stamps waiting to leave
            their mark. In one corner, an elderly man with kind eyes and nimble
            fingers meticulously stitched the spine of a hardcover, his years of
            experience evident in every precise movement. Customers often
            wandered in, drawn by the promise of preserving their cherished
            volumes or creating bespoke journals that would hold their innermost
            thoughts. Each book that passed through the hands of the artisans
            left with a piece of their heart, a symbol of their dedication to
            the art of bookbinding. Amidst the hum of conversation and the
            gentle tap of hammers, the bookbinding company thrived, a sanctuary
            for those who believed in the magic of the written word and the
            beauty of a well-bound book.
          </Typography>
        </Flex>
      </Flex>
    </TitleLayout>
  </Layout>
);

export default AboutUs;
