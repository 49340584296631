import { Box, Paper } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "theme.main";
import { FOOTER_HEIGHT } from "constants/index";

const Flex = styled(Box)`
  display: flex;
`;

const Grid = styled(Box)`
  display: grid;
`;

/**
 * Red dot on top right of icon to indicate notifications
 *
 * TODO: change into material UI Badge
 */
const Notification = styled(Box)`
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: ${theme.palette.common.white};
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  position: absolute;
  top: 5px;
  right: 0;
`;

/**
 * A mask to cover the page
 *
 * TODO: use material UI backdrop
 */
const BackDrop = styled(Box)`
  position: fixed;
  inset: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.45);
  cursor: pointer;
`;

const FooterEl = styled.footer`
  display: flex;
  flex-direction: column;
  position: relative;
  top: auto;
  bottom: 0;
  align-items: center;
  height: ${FOOTER_HEIGHT};
  background: ${theme.palette.common.black};
  color: ${theme.palette.common.white};
`;

/**
 * When classname "disappear is added", this element shrinks away
 * seamlessly as long as height is explicitly defined.
 *
 * It will seem janky if height=0 on this Element's CSS
 */
const AnimatedPaper = styled(Paper)`
  &.disappear {
    transform: scale(0);
    opacity: 0;
    padding: 0;
    margin: 0;
    transition: all 250ms ease-in-out;
    height: 0;
  }
`;

export { Flex, Grid, BackDrop, AnimatedPaper, Notification, FooterEl };
