import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { MainLayout as Layout, TitleLayout } from "components/layout";
import { EMAIL_REGEX } from "constants/index";
import { Flex } from "elements/common";
import { Form } from "elements/forms";
import {
  ConfirmationType,
  ResetPasswordInputType,
  ResetPasswordFormType,
} from "types";

const EmailInput = ({ errors, register }: ResetPasswordInputType) => (
  <FormControl error={Boolean(errors.email)} margin="normal">
    <InputLabel htmlFor="component-outlined">Email</InputLabel>
    <OutlinedInput
      id="component-outlined"
      label="Email"
      {...register("email", {
        required: true,
        pattern: EMAIL_REGEX,
      })}
    />
    {errors.email?.type === "required" && (
      <FormHelperText id="component-error-text">
        An email is required
      </FormHelperText>
    )}
    {errors.email?.type === "pattern" && (
      <FormHelperText id="component-error-text">
        Enter a valid email
      </FormHelperText>
    )}
  </FormControl>
);

const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<ConfirmationType>({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({ defaultValues: { email: "" } });

  const handleResetPassword = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      setConfirmation({
        open: true,
        message:
          "If a user account exists, your email will receive a password reset link shortly",
      });
      reset();
    }, 800);
  };

  return (
    <Layout>
      <TitleLayout title="Reset password">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Snackbar
            open={confirmation.open}
            sx={{
              width: "300px",
              textAlign: "left",
            }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={10000}
            onClose={() => setConfirmation({ open: false })}
          >
            <Alert severity={confirmation.err ? "error" : "success"}>
              {confirmation.message}
            </Alert>
          </Snackbar>
          <Form onSubmit={handleSubmit(handleResetPassword)} fixedWidth>
            <EmailInput errors={errors} register={register} />

            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              size="large"
              type="submit"
              sx={{
                borderRadius: "2rem",
                fontSize: "1.1em",
                borderWidth: "medium",
                marginTop: "1rem",
                width: "250px",
                alignSelf: "center",
              }}
            >
              Send reset link
            </LoadingButton>
          </Form>
        </Flex>
      </TitleLayout>
    </Layout>
  );
};

export default ResetPassword;
