import styled from "@emotion/styled";

// TODO: is this the best place for this??
export const IconClickable = styled.svg`
  user-select: none;
  width: 0.75em;
  height: 0.75em;
  fill: currentColor;
`;

export const StyledLogo = styled.svg`
  user-select: none;
  width: 4.2em;
  height: 4.2em;
  fill: currentColor;
`;
