import React, { useState } from "react";

import { MainLayout as Layout, TitleLayout } from "components/layout";
import { Box, Modal, Paper, Typography } from "@mui/material";
import { Flex } from "elements/common";
import moment from "moment";
import { theme } from "theme.main";
import { fakeOrders } from "tests/fake-returns";
import { OrderModalProps, TitleAndInfoProps } from "types";
import { pluralize } from "utils/strings";
import { ListingOrder } from "types/api";

const OrderModal = ({ open, handleCloseModal }: OrderModalProps) => (
  <Modal
    open={open}
    onClose={handleCloseModal}
    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <Flex padding="2rem" bgcolor={theme.palette.common.white}>
      Order details here, list items, ask for review if not there
    </Flex>
  </Modal>
);

const TitleAndInfo = ({
  layout = "row",
  title,
  content,
  boldTitle = false,
}: TitleAndInfoProps) => (
  <Flex flexDirection={layout}>
    <Typography fontWeight={boldTitle ? 700 : 500}>{title}</Typography>
    <Typography marginLeft={layout === "row" ? "0.5rem" : 0}>
      {content}
    </Typography>
  </Flex>
);

const PastOrders = () => {
  const [fullOrderView, setFullOrderView] = useState(false);

  const calculateAvgRating = (listings: ListingOrder[]) => {
    const listingsWithReviews = listings.filter((x) => x.review);
    if (!listingsWithReviews.length) return "N/A";
    const totalRatings = listingsWithReviews.reduce(
      (total, next) => total + (next.review?.rating || 0),
      0,
    );
    return totalRatings / listingsWithReviews.length + " stars";
  };

  return (
    <Layout>
      <TitleLayout title="Past Orders">
        <Typography variant="body2">
          * only orders made through this site
        </Typography>
        <Flex alignItems="center" flexDirection="column" marginTop="2rem">
          {fakeOrders.map((order, idx) => (
            <Box key={idx}>
              <OrderModal
                open={fullOrderView}
                handleCloseModal={() => setFullOrderView(false)}
              />
              <Paper
                onClick={() => setFullOrderView(true)}
                elevation={4}
                sx={{
                  borderRadius: 0,
                  marginBottom: "1rem",
                  padding: "1.5rem",
                  maxWidth: "600px",
                  width: "80vw",
                  cursor: "pointer",
                  "&:hover": { background: "#e1dbd2" },
                }}
              >
                <Flex flexDirection="column" marginBottom="2rem">
                  <TitleAndInfo
                    title="Confirmation #:"
                    content={order.confirmation_number}
                    boldTitle
                  />
                  <Typography textAlign="left" variant="body2" color="GrayText">
                    {moment(order.created_at).format("MMMM Do, YYYY")}
                  </Typography>
                </Flex>
                <Flex justifyContent="space-between">
                  <TitleAndInfo
                    title="Avg Review"
                    content={calculateAvgRating(order.items)}
                    layout="column"
                    boldTitle
                  />
                  <TitleAndInfo
                    title="Products"
                    content={pluralize(order.items.length, "item")}
                    layout="column"
                    boldTitle
                  />
                  <TitleAndInfo
                    title="Total Price"
                    content={`$${order.total_price}`}
                    layout="column"
                    boldTitle
                  />
                </Flex>
              </Paper>
            </Box>
          ))}
        </Flex>
      </TitleLayout>
    </Layout>
  );
};

export default PastOrders;
