import styled from "@emotion/styled";

import { theme } from "theme.main";
import { Flex } from "./common";

const FilterHeader = styled(Flex)`
  margin: ${theme.spacing(4, 16)};
`;

export { FilterHeader };
