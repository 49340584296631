import React from "react";
import { useNavigate } from "react-router-dom";

import { NavItem, MobileMenu } from "elements/navigation";
import { theme } from "theme.main";
import { MobileNavigationProps } from "types";

const MobileNavigation = ({ navOpen, setNavOpen }: MobileNavigationProps) => {
  const navigate = useNavigate();

  return (
    <MobileMenu
      display={["flex", "none", "none", "none"]}
      flexDirection="column"
      width={navOpen ? "100%" : 0}
      padding="1rem 0"
      className="mobile-menu"
      sx={{
        background: theme.palette.common.white,
        opacity: navOpen ? 1 : 0,
        marginLeft: navOpen ? 0 : "-100px",
      }}
    >
      <NavItem
        sx={{ color: theme.palette.common.black }}
        onClick={() => {
          navigate("/shop/");
          setNavOpen(false);
        }}
      >
        Shop
      </NavItem>
      <NavItem
        sx={{ color: theme.palette.common.black }}
        onClick={() => {
          navigate("/about-us/");
          setNavOpen(false);
        }}
      >
        About Us
      </NavItem>
      <NavItem
        sx={{ color: theme.palette.common.black }}
        onClick={() => {
          navigate("/support/");
          setNavOpen(false);
        }}
      >
        Support
      </NavItem>
      <NavItem
        sx={{ color: theme.palette.common.black }}
        onClick={() => {
          navigate("/blog/");
          setNavOpen(false);
        }}
      >
        Blog
      </NavItem>
    </MobileMenu>
  );
};

export default MobileNavigation;
