import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import Navigation from "components/navigation";
import Footer from "components/footer";
import AboutUs from "pages/about-us";
import Cart from "pages/cart";
import Home from "pages/home";
import Shop from "pages/shop";
import Support from "pages/support";
import TrackPackage from "pages/track-package";
import PastOrders from "pages/past-orders";
import DetailListing from "pages/detailed-listing";
import { Flex } from "elements/common";
import LogIn from "pages/login";
import SignUp from "pages/sign-up";
import ResetPassword from "pages/reset-password";
import { Blog, BlogPost } from "pages/blog";
import PageNotFound from "pages/page-not-found";

const Router = () => (
  <Flex className="App">
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="cart" element={<Cart />} />
      <Route path="support" element={<Support />} />
      <Route path="track-package" element={<TrackPackage />} />
      <Route path="past-orders" element={<PastOrders />} />
      <Route path="login" element={<LogIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="blog" element={<Outlet />}>
        <Route index element={<Blog />} />
        <Route path="posts/:blogPostingTitle" element={<BlogPost />} />
      </Route>

      {/* Shop is a template loading both index & :id with UI nesting */}
      <Route path="shop" element={<Outlet />}>
        <Route index element={<Shop />} />
        <Route path=":listingID" element={<DetailListing />} />
      </Route>
      {/* 404 Page needs to be at the bottom */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
    <Footer />
  </Flex>
);

export default Router;
