import { ListingType, Order, ReviewProps } from "types/api";

export const fakeListings: ListingType[] = [
  {
    key: "book1",
    title: "Hunger Games",
    price: "35.70",
    discount: "45% off", // this is not how discounts will work
    favorite: true,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
      {
        key: "third",
        name: "third-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "fourth",
        name: "fourth-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
      // {
      //   key: "sixth",
      //   name: "sixth-pic",
      //   src: "book6_ksgjme",
      //   resource_type: "video",
      // },
    ],
  },
  {
    key: "book2",
    title: "1984",
    price: "67.30",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
      {
        key: "third",
        name: "third-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "fourth",
        name: "fourth-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book3",
    title: "Babel",
    price: "98.23",
    favorite: true,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
      // {
      //   key: "second",
      //   name: "first-pic",
      //   src: "book6_ksgjme",
      //   resource_type: "video",
      // },
    ],
  },
  {
    key: "book4",
    title: "Hunger Games",
    price: "35.70",
    discount: "45% off", // this is not how discounts will work
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book5",
    title: "1984",
    price: "67.30",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book6",
    title: "Babel",
    price: "98.23",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book7",
    title: "Hunger Games",
    price: "35.70",
    favorite: false,
    discount: "45% off", // this is not how discounts will work
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book8",
    title: "1984",
    price: "67.30",
    favorite: true,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book9",
    title: "Babel",
    price: "98.23",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book10",
    title: "Hunger Games",
    price: "35.70",
    favorite: false,
    discount: "45% off", // this is not how discounts will work
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book11",
    title: "1984",
    price: "67.30",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book12",
    title: "Babel",
    price: "98.23",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book13",
    title: "Hunger Games",
    price: "35.70",
    favorite: true,
    discount: "45% off", // this is not how discounts will work
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book14",
    title: "1984",
    price: "67.30",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book15",
    title: "Babel",
    price: "98.23",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book16",
    title: "Hunger Games",
    price: "35.70",
    favorite: false,
    discount: "45% off", // this is not how discounts will work
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book1_l7pvax",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book4_efezrv",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book17",
    title: "1984",
    price: "67.30",
    favorite: true,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book2_xh8xaf",
        resource_type: "image",
      },
      {
        key: "second",
        name: "second-pic",
        src: "book5_adjcs2",
        resource_type: "image",
      },
    ],
  },
  {
    key: "book18",
    title: "Babel",
    price: "98.23",
    favorite: false,
    mediaList: [
      {
        key: "first",
        name: "first-pic",
        src: "book3_ry2v6p",
        resource_type: "image",
      },
      // {
      //   key: "second",
      //   name: "first-pic",
      //   src: "book6_ksgjme",
      //   resource_type: "video",
      // },
    ],
  },
];

export const fakeReviews: ReviewProps[] = [
  {
    rating: 4.5,
    reviewText:
      "Loved this book, it was so pretty, and exactly like the picture!",
    reviewSrc: "Etsy",
  },
  { rating: 5, reviewText: "Great product! Loved it", reviewSrc: "Facebook" },
  {
    rating: 4.2,
    reviewText:
      "The product arrived in perfect condition and was even more stunning in person than I expected. The colors are vibrant and the details are exquisite. The packaging was top-notch, ensuring no damage during shipping.",
    reviewSrc: "Instagram",
  },
  {
    rating: 4,
    reviewText:
      "Absolutely thrilled with my new book! It was securely packaged, and the quality of the artwork is exceptional. It looks gorgeous on my wall and has received many compliments from guests.",
    reviewSrc: "Etsy",
  },
  {
    rating: 3.5,
    reviewText:
      "This book exceeded my expectations. The shipping was fast, and the artwork was well-protected with bubble wrap and sturdy cardboard. The piece itself is a true work of art, with vivid colors and a striking composition.",
    reviewSrc: "Etsy",
  },
  {
    rating: 4.3,
    reviewText:
      "I couldn't be happier with my purchase. The book arrived on time, and the quality is superb. The frame was intact, and the artwork is even more beautiful than I imagined. The seller clearly takes pride in their work.",
    reviewSrc: "Private email",
  },
  {
    rating: 4.5,
    reviewText:
      "The book arrived in perfect condition and was beautifully packaged. The colors and brushwork are incredible, and it looks fantastic on my living room wall. The delivery was prompt and hassle-free.",
    reviewSrc: "Etsy",
  },
  {
    rating: 5,
    reviewText:
      "The artwork is fantastic, and the shipping was flawless. The book was wrapped carefully, and the quality of the piece is outstanding. It adds a wonderful touch to my home decor and was worth every penny.",
    reviewSrc: "Facebook",
  },
  {
    rating: 4.2,
    reviewText:
      "I love my new book! The shipping was fast, and the book was carefully packaged to avoid any damage. The artwork itself is stunning, with rich colors and fine details. It's a real centerpiece in my living room",
    reviewSrc: "Instagram",
  },
  {
    rating: 4.3,
    reviewText:
      "I'm very pleased with this book. It was shipped quickly and arrived in pristine condition. The colors are vibrant, and the quality is superb. It's a great addition to my home decor.",
    reviewSrc: "Private email",
  },
  {
    rating: 4.5,
    reviewText:
      "The book arrived earlier than expected and was in perfect condition. The colors are vibrant, and the details are sharp. The shipping was handled professionally, and the book looks amazing on my wall.",
    reviewSrc: "Etsy",
  },
  {
    rating: 5,
    reviewText:
      "The book arrived in perfect condition, thanks to the careful packaging. The artwork is even more beautiful than I anticipated. The shipping was prompt, and I'm thrilled with how it looks in my space.",
    reviewSrc: "Facebook",
  },
  {
    rating: 4.2,
    reviewText:
      "I received my book faster than expected, and it was in immaculate condition. The artwork is stunning, and the quality of the print is excellent. The shipping was handled professionally, and I'm very satisfied.",
    reviewSrc: "Instagram",
  },
  {
    rating: 4.3,
    reviewText:
      "Very impressed with the quality of both the book and the shipping. The artwork is gorgeous, and the packaging ensured it arrived without any issues. It's a beautiful addition to my home.",
    reviewSrc: "Private email",
  },
  {
    rating: 4.5,
    reviewText:
      "The book arrived on time and in perfect condition. The packaging was secure, and the artwork is absolutely beautiful. It looks fantastic on my wall, and I couldn't be happier with the purchase.",
    reviewSrc: "Etsy",
  },
  {
    rating: 5,
    reviewText:
      "I'm extremely happy with my book purchase. The shipping was fast, and the book was carefully wrapped to prevent any damage. The artwork itself is vibrant and beautifully done.",
    reviewSrc: "Facebook",
  },
  {
    rating: 4.2,
    reviewText:
      "The book was shipped promptly and arrived in excellent condition. The colors are as described, and the piece is even more beautiful in person. I'm very satisfied with my purchase and the overall experience.",
    reviewSrc: "Instagram",
  },
  {
    rating: 4.3,
    reviewText:
      "The book is beautiful, and the shipping process was smooth. The artwork arrived in perfect condition, thanks to the secure packaging. It's a fantastic piece that has added so much character to my home.",
    reviewSrc: "Private email",
  },
];

export const fakeOrders: Order[] = [
  {
    confirmation_number: "234234423",
    total_price: 45.42,
    items: [
      {
        key: "book2",
        title: "1984",
        price: "67.30",
        review: {
          rating: 4.5,
          reviewText:
            "Loved this book, it was so pretty, and exactly like the picture!",
          reviewSrc: "Etsy",
        },
        mediaList: [
          {
            key: "first",
            name: "first-pic",
            src: "book2_xh8xaf",
            resource_type: "image",
          },
          {
            key: "second",
            name: "second-pic",
            src: "book5_adjcs2",
            resource_type: "image",
          },
        ],
      },
      {
        key: "book4",
        title: "Hunger Games",
        price: "35.70",
        review: {
          rating: 4,
          reviewText:
            "Loved this book, it was so pretty, and exactly like the picture!",
          reviewSrc: "Etsy",
        },
        mediaList: [
          {
            key: "first",
            name: "first-pic",
            src: "book1_l7pvax",
            resource_type: "image",
          },
          {
            key: "second",
            name: "second-pic",
            src: "book4_efezrv",
            resource_type: "image",
          },
        ],
      },
    ],
    created_at: new Date("08/13/2024"),
    shipping_date: new Date("08/17/2024"),
  },
  {
    confirmation_number: "sjfjsk324f",
    total_price: 65.42,
    items: [
      {
        key: "book17",
        title: "1984",
        price: "67.30",
        mediaList: [
          {
            key: "first",
            name: "first-pic",
            src: "book2_xh8xaf",
            resource_type: "image",
          },
          {
            key: "second",
            name: "second-pic",
            src: "book5_adjcs2",
            resource_type: "image",
          },
        ],
      },
    ],
    created_at: new Date("08/13/2024"),
    shipping_date: null,
  },
  {
    confirmation_number: "abcd1234jkfs",
    total_price: 98.42,
    items: [
      {
        key: "book6",
        title: "Babel",
        price: "98.23",
        review: {
          rating: 4,
          reviewText:
            "Loved this book, it was so pretty, and exactly like the picture!",
          reviewSrc: "Etsy",
        },
        mediaList: [
          {
            key: "first",
            name: "first-pic",
            src: "book3_ry2v6p",
            resource_type: "image",
          },
        ],
      },
    ],
    created_at: new Date("07/13/2024"),
    shipping_date: new Date("07/17/2024"),
  },
];
