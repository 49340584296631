import React from "react";

import { MainContainer } from "elements/layout";
import { MainLayoutType } from "types";
import { theme } from "theme.main";

/**
 * This is the main layout that is the template for every page on this site
 *
 * @param children: All contents within page
 */
const MainLayout = ({ children, bgColor }: MainLayoutType) => {
  return (
    <MainContainer
      className="content-wrapper"
      sx={{ backgroundColor: bgColor || theme.palette.common.white }}
    >
      {children}
    </MainContainer>
  );
};

export default MainLayout;
