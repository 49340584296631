import frontMatterFormatter from "gray-matter";
import { ImportedBlogPost } from "types";

import { getSlug, importAll } from "utils/blog";

// Grabbing all the file names of blog posts
const markdownContext = require.context(
  "components/posts/",
  false,
  /components\/posts\/[a-z\-]+\.md$/, //eslint-disable-line
);

/**
 * With the list of all the .md files under my blog post folder, returns
 * a list of the slug (file name) and the metadata
 *
 * @returns [{
 *   slug: "first-blog-post",
 *   data: {
 *     title: "First Blog Post",
 *     description: "This is my very first post, hope you like it!",
 *     pub_date: new Date("08-02-2024"),
 *     prev: ""
 *     next: "next-blog-post"
 * }]
 */
export const getPosts: () => Promise<ImportedBlogPost[]> = async () => {
  const imported_posts = await Promise.all(
    importAll(markdownContext).map((static_file) =>
      fetch(static_file as string)
        .then((response) => response.text())
        .then((response) => {
          const parsed_post = frontMatterFormatter(response);
          parsed_post.data.pub_date = new Date(parsed_post.data.pub_date);

          return {
            slug: getSlug(static_file as string),
            data: parsed_post.data,
          } as ImportedBlogPost;
        })
        .catch((err) => console.error(err)),
    ),
  );

  const posts = imported_posts
    .filter((x) => x != null)
    .sort((a, b) => (b.data.pub_date as any) - (a.data.pub_date as any));

  return posts;
};
