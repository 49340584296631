import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import ThinHeart from "assets/thin-heart";
import Profile from "assets/profile";
import Logo from "assets/logo";
import MiniProductView from "components/mini-listing";
import Mask from "components/mask";
import { NavItem, NavDivider, Header } from "elements/navigation";
import { Flex } from "elements/common";
import { SavedProductsProps } from "types";
import CartItemsMini from "./cart-items";
import MobileNavigation from "./mobile-nav";
import { fakeListings } from "tests/fake-returns";

import { theme } from "theme.main";

type SavedItemsType = {
  open: boolean;
  type: "favorites" | "wishlist";
};

const MobileNavIcon = ({ navOpen }: { navOpen: boolean }) => {
  return <IconButton>{navOpen ? <CloseIcon /> : <MenuIcon />}</IconButton>;
};

/**
 * Wishlist or Favorited Items
 */
const SavedProducts = ({ open, handleOnClose, type }: SavedProductsProps) => {
  const [products, setProducts] = useState(fakeListings.slice(0, 5)); // TODO: recoil based on type?

  const removeItem = (id: number) => {
    setTimeout(() => {
      const items_copy = [...products];
      items_copy.splice(id, 1);
      setProducts(items_copy);
    }, 300);
  };

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <Flex padding="1rem" flexDirection="column" alignItems="center">
        <Typography variant="h3" textTransform="capitalize" marginBottom="1rem">
          {type}
        </Typography>
        <Button
          color="inherit"
          onClick={handleOnClose}
          sx={{
            display: ["flex", "none"],
            fontSize: "40px",
            height: "40px",
            borderRadius: "50px",
            position: "absolute",
            right: 0,
          }}
        >
          ×
        </Button>
        {products.map((product, idx) => (
          <MiniProductView
            key={product.key}
            item={product}
            idx={idx}
            handleRemoveItem={removeItem}
          />
        ))}
      </Flex>
    </Drawer>
  );
};

const ProfileDropdown = ({
  loggedIn,
  setSavedItems,
}: {
  loggedIn: boolean;
  setSavedItems: (x: SavedItemsType) => void;
}) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        className="profile-icon"
        style={{
          display: loggedIn ? "flex" : "none",
          padding: "0.5rem",
          fontSize: "2.5rem",
          marginLeft: "0.5rem",
        }}
        onClick={(e) => setDropdownOpen(e.currentTarget)}
      >
        <Profile />
      </IconButton>

      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={dropdownOpen}
        open={Boolean(dropdownOpen)}
        onClose={() => setDropdownOpen(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* TODO: add a view/edit profile button */}
        <MenuItem
          onClick={() => {
            setSavedItems({ open: true, type: "wishlist" });
            setDropdownOpen(null);
          }}
          dense={true}
        >
          View Wishlist
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/past-orders/");
            setDropdownOpen(null);
          }}
          dense={true}
        >
          View Past Orders
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => setDropdownOpen(null)} dense={true}>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [savedItems, setSavedItems] = useState<SavedItemsType>({
    open: false,
    type: "favorites",
  });
  const navigate = useNavigate();

  const loggedIn: boolean = true; // TODO: find out if user is logged in

  return (
    <>
      <Header className="header" position="sticky">
        <Box
          display={["flex", "none", "none", "none"]}
          onClick={() => setNavOpen(!navOpen)}
          style={{ cursor: "pointer" }}
        >
          <MobileNavIcon navOpen={navOpen} />
        </Box>
        <Box>
          <Button
            sx={{
              color: theme.palette.common.black,
              padding: "0 0.75rem",
              borderRadius: 0,
              "&:hover": { background: "none" },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Logo />
          </Button>
        </Box>
        <Flex>
          <Box display={["none", "flex", "flex", "flex"]}>
            <NavItem
              sx={{ color: theme.palette.common.black }}
              onClick={() => {
                navigate("/shop/");
              }}
            >
              Shop
            </NavItem>
            <NavDivider />
            <NavItem
              sx={{ color: theme.palette.common.black }}
              onClick={() => {
                navigate("/about-us/");
              }}
            >
              About Us
            </NavItem>
            <NavDivider />
            <NavItem
              sx={{ color: theme.palette.common.black }}
              onClick={() => {
                navigate("/support/");
              }}
            >
              Support
            </NavItem>
            <NavDivider />

            <NavItem
              sx={{ color: theme.palette.common.black }}
              onClick={() => {
                navigate("/blog/");
              }}
            >
              Blog
            </NavItem>
            <NavDivider />
          </Box>
          <Box display="flex" alignItems="center">
            <NavItem
              onClick={() => {
                navigate("/login/");
              }}
              style={{
                display: loggedIn ? "none" : "block",
                color: theme.palette.common.black,
              }}
            >
              Sign In
            </NavItem>
            <ProfileDropdown
              loggedIn={loggedIn}
              setSavedItems={setSavedItems}
            />

            <IconButton
              style={{
                display: loggedIn ? "flex" : "none",
                padding: "0.5rem",
                fontSize: "2.5rem",
              }}
              onClick={() => {
                setSavedItems({ open: true, type: "favorites" });
              }}
            >
              <ThinHeart />
            </IconButton>

            <CartItemsMini />
          </Box>
        </Flex>
        {/* TODO: determine how many items in cart */}
      </Header>
      <MobileNavigation navOpen={navOpen} setNavOpen={setNavOpen} />
      <Mask openDrawer={navOpen} handleToggleDrawer={() => setNavOpen(false)} />

      <SavedProducts
        open={savedItems.open}
        handleOnClose={() => setSavedItems({ ...savedItems, open: false })}
        type={savedItems.type}
      />
    </>
  );
};

export default Navigation;
