import styled from "@emotion/styled";

import { theme } from "theme.main";
import { Flex } from "./common";

export const AboutUsImageWrapper = styled(Flex)`
  img {
    margin-top: 2rem;
    max-width: 80vw;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 0;
      max-width: 50%;
    }
  }
`;