import React, { ReactNode, useEffect, useState } from "react";

import { Box, Collapse, Typography } from "@mui/material";

import { Flex } from "elements/common";
import ButtonLink from "./buttons/link";

type CollapsedInfoProps = {
  header: string;
  headerContent?: ReactNode;
  openText: string;
  closeText?: string;
  saveAndClose?: boolean;
  children: ReactNode;
};

/**
 * Given a title and contents, creates an accordian effect to show & hide contents.
 */
const CollapsedInfo = ({
  header,
  headerContent,
  openText,
  closeText,
  saveAndClose,
  children,
}: CollapsedInfoProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (saveAndClose) setOpen(false);
  }, [saveAndClose]);

  return (
    <Flex marginTop="1rem" flexDirection="column" sx={{ borderRadius: "5px" }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600}>{header}</Typography>
        <ButtonLink
          handleOnClick={() => setOpen(!open)}
          text={open ? closeText || openText : openText}
          underline
        />
      </Flex>
      <Box textAlign="left">{headerContent}</Box>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          textAlign: "left",
        }}
        unmountOnExit
      >
        {children}
      </Collapse>
    </Flex>
  );
};

export default CollapsedInfo;
