import { Link, SxProps, Theme } from "@mui/material";
import React from "react";
import { theme } from "theme.main";

type ButtonLinkProps = {
  handleOnClick: (x: any) => void;
  text: string;
  underline?: boolean;
  sx?: SxProps<Theme>;
};

/**
 * Button that appears like a link
 *
 * @param text - text that displays on button
 * @param handleOnClick - function that triggers when button is clicked
 */
const ButtonLink = ({ handleOnClick, text, underline, sx}: ButtonLinkProps) => {
  return (
    <Link
      onClick={handleOnClick}
      sx={{
        padding: "0.55rem",
        alignSelf: "center",
        textDecoration: underline ? "underline" : "none",
        fontWeight: "600",
        cursor: "pointer",
        fontSize: "0.95rem",
        "&:hover": { color: theme.palette.primary.dark },
        ...sx
      }}
    >
      {text}
    </Link>
  );
};

export default ButtonLink;
