import React from "react";

import { CARD_WIDTH } from "constants/index";
import { ListingImages } from "elements/listing";
import { MediaWrapperProps } from "types";
import ImageBuilder from ".";

const MediaWrapper = ({
  title,
  primaryMedia,
  secondaryMedia,
  sx,
}: MediaWrapperProps) => (
  <ListingImages className="media-wrapper" position="relative" sx={sx}>
    <ImageBuilder
      resourceType={primaryMedia.resource_type}
      className={`top-listing-image ${secondaryMedia ? "covering" : ""}`}
      fileName={primaryMedia.src}
      altText={title}
      width={CARD_WIDTH}
    />
    {secondaryMedia && (
      <ImageBuilder
        resourceType={secondaryMedia.resource_type}
        fileName={secondaryMedia.src}
        altText={title}
        width={CARD_WIDTH}
      />
    )}
  </ListingImages>
);

export default MediaWrapper;
