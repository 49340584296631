import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider, IconButton, Menu, MenuItem } from "@mui/material";

import ShoppingCart from "assets/shopping-cart";
import { Flex, Notification } from "elements/common";
import { fakeListings } from "tests/fake-returns";
import MiniProductView from "components/mini-listing";

const CartItemsMini = () => {
  const navigate = useNavigate();
  const loc = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState<null | HTMLElement>(null);
  const [cartItems, setCartItems] = useState(fakeListings.slice(0, 2)); // TODO: make recoil?

  const removeItem = (id: number) => {
    setTimeout(() => {
      const items_copy = [...cartItems];
      items_copy.splice(id, 1);
      setCartItems(items_copy);
    }, 300);
  };

  return (
    <>
      <IconButton
        style={{
          display: "flex",
          padding: "0.5rem",
          fontSize: "2.5rem",
        }}
        onClick={(e) => {
          if (loc.pathname.includes("cart") || cartItems.length === 0) return;
          setDropdownOpen(e.currentTarget);
        }}
        className="shopping-cart"
      >
        <ShoppingCart />
        <Notification sx={{ opacity: cartItems.length }}>
          {cartItems.length}
        </Notification>
      </IconButton>

      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={dropdownOpen}
        open={Boolean(dropdownOpen)}
        onClose={() => setDropdownOpen(null)}
        sx={{ ".MuiMenu-list": { padding: 0 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Flex
          flexDirection="column"
          padding="1rem 1rem 0 1rem"
          alignItems="center"
        >
          {cartItems.length ? (
            cartItems.map((listing, idx) => (
              <MiniProductView
                key={listing.key}
                item={listing}
                idx={idx}
                handleRemoveItem={removeItem}
              />
            ))
          ) : (
            <Flex width="320px" padding="1rem" justifyContent="center">
              You have no cart items
            </Flex>
          )}
        </Flex>

        <Divider />
        <MenuItem
          onClick={() => {
            setDropdownOpen(null);
            navigate(cartItems.length ? "/cart/" : "/shop/");
          }}
          dense={true}
          sx={{
            fontSize: "1rem",
            padding: "1rem",
            justifyContent: "center",
          }}
        >
          {cartItems.length ? "Proceed to Checkout" : "Continue Shopping"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default CartItemsMini;
