import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { Flex } from "elements/common";
import { ListingProps, TitleCardProps } from "types";
import { ListingCard, ListingWrapper, PhotoIcon } from "elements/listing";
import Badge from "components/badge";
import MediaWrapper from "components/image-builder/media-wrapper";

type FloatingFavIconProps = {
  favorite: boolean;
  disableAnimation?: boolean;
};

export const FloatingFavIcon = ({
  favorite,
  disableAnimation = false,
}: FloatingFavIconProps) => (
  <PhotoIcon
    className={`photo-icon ${disableAnimation ? "" : "animate"} ${
      favorite && "favorite"
    }`}
  >
    <FavoriteBorderIcon
      className="empty-heart"
      color="primary"
      style={{ width: "20px", height: "20px" }}
    />
    <FavoriteIcon
      className="full-heart"
      style={{
        color: "red",
        width: "20px",
        height: "20px",
      }}
    />
  </PhotoIcon>
);

const TitleCard = ({ badge, title, subtitle }: TitleCardProps) => (
  <Flex className="title-bar" flexDirection="column" margin="0 1rem 1rem 1rem">
    {badge && <Badge text={badge} />}
    <Typography variant="h5" textAlign="left" fontSize="1.3rem">
      {title}
    </Typography>
    <Typography textAlign="left">{subtitle}</Typography>
  </Flex>
);

const Listing = ({
  bookID,
  title,
  subtitle,
  badge,
  primaryMedia,
  favorite,
  secondaryMedia,
}: ListingProps) => {
  const navigate = useNavigate();

  return (
    <ListingWrapper>
      <FloatingFavIcon favorite={favorite} />
      <ListingCard
        className="listing-card"
        elevation={6}
        onClick={() => {
          navigate(`/shop/${bookID}/`);
        }}
      >
        <MediaWrapper
          title={title}
          primaryMedia={primaryMedia}
          secondaryMedia={secondaryMedia}
        />
      </ListingCard>
      <TitleCard badge={badge} title={title} subtitle={subtitle} />
    </ListingWrapper>
  );
};

export default Listing;
