import React from "react";

import { Typography } from "@mui/material";

import CollapsedInfo from "components/collapse";
import { Flex } from "elements/common";
import { theme } from "theme.main";

const PaymentOptions = () => {
  return (
    <CollapsedInfo
      header="Payment Info"
      openText="Edit"
      closeText="Save"
      headerContent={
        <Typography color={theme.palette.grey[700]} variant="body2">
          Visa 3423
        </Typography>
      }
    >
      <Flex padding="1rem">
        Once I integrate with stripe, we'll have CC, Debit card, venmo, paypal,
        apple pay, google pay
      </Flex>
    </CollapsedInfo>
  );
};

export default PaymentOptions;
