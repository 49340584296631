import React from "react";

import { IconClickable } from "./styles";

const Icon = () => (
  <IconClickable
    fill="#f2521b"
    width="800px"
    height="800px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.417 3.26c0-0.432 0.047-0.693 0.786-0.693h9.953c1.734 0 2.698 1.479 3.391 4.255l0.557 2.224h1.693c0.307-6.307 0.573-9.047 0.573-9.047s-4.26 0.479-6.786 0.479h-12.729l-6.818-0.219v1.828l2.297 0.432c1.615 0.323 2 0.661 2.135 2.141 0 0 0.146 4.365 0.146 11.521 0 7.182-0.12 11.479-0.12 11.479 0 1.302-0.521 1.781-2.12 2.099l-2.297 0.443v1.797l6.839-0.219h11.401c2.583 0 8.521 0.219 8.521 0.219 0.141-1.563 1-8.641 1.141-9.417h-1.599l-1.714 3.88c-1.339 3.036-3.302 3.255-5.479 3.255h-6.542c-2.172 0-3.219-0.849-3.219-2.729v-9.922c0 0 4.823 0 6.385 0.125 1.214 0.089 1.948 0.438 2.344 2.13l0.521 2.26h1.88l-0.12-5.703 0.255-5.74h-1.854l-0.599 2.521c-0.375 1.656-0.641 1.958-2.339 2.135-2.219 0.224-6.422 0.182-6.422 0.182v-11.714h-0.063z" />
  </IconClickable>
);

export default Icon;
