import React, { useState } from "react";

import { Divider, Typography } from "@mui/material";

import CollapsedInfo from "components/collapse";
import { AddAddressForm } from "components/forms";
import { Flex } from "elements/common";
import { theme } from "theme.main";

const ShippingDetails = () => {
  const [closeForm, setCloseForm] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("No Address"); // TODO: reocoil

  return (
    <CollapsedInfo
      header="Delivery To"
      openText="Edit"
      closeText="Close"
      saveAndClose={closeForm}
      headerContent={
        <Typography
          variant="body2"
          color={theme.palette.grey[700]}
          whiteSpace="pre-line"
        >
          {/* TODO, italicized and error text when empty */}
          {shippingAddress}
        </Typography>
      }
    >
      <Flex padding="1rem 0" flexDirection="column">
        <Divider sx={{ marginBottom: "1.5rem" }}>shipping address</Divider>
        {/* Add past addresses */}

        {/* if no saved address, show new shipping form, otherwise, show button "Add new address" */}
        <AddAddressForm
          setCloseForm={setCloseForm}
          setShippingAddress={setShippingAddress}
        />
        <Divider sx={{ marginTop: "1.5rem" }} />
      </Flex>
    </CollapsedInfo>
  );
};

export default ShippingDetails;
