import styled from "@emotion/styled";
import { Container } from "@mui/material";

import { theme } from "theme.main";
import { Flex } from "./common";

const LayoutContainer = styled(Flex)`
  flex-direction: column;
  padding: 1rem;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 5em;
  }

  &.full-page {
    padding: 0;

    .title {
      padding-top: 5rem;
    }
  }
`;

const MainContainer = styled(Container)`
  flex-grow: 1;

  // overriding MUI Container styles
  &.content-wrapper {
    max-width: none;
    padding: 0;
  }
`;

export { LayoutContainer, MainContainer };
