import React from "react";

type SVGProps = {
  width?: string | number;
  height?: string | number;
};

const Icon = ({ width = 309, height = 238 }: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 309 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M288.176 19.1169H265.166C263.616 19.1121 262.13 18.4938 261.034 17.3971C259.938 16.3004 259.32 14.8144 259.316 13.2638V6.43523C259.32 4.88464 259.938 3.39865 261.034 2.30191C262.13 1.20518 263.616 0.586854 265.166 0.582031H288.176C289.727 0.586843 291.213 1.20516 292.309 2.3019C293.405 3.39864 294.022 4.88464 294.026 6.43523V13.2638C293.99 14.8041 293.362 16.2713 292.273 17.361C291.183 18.4508 289.717 19.0795 288.176 19.1169ZM265.166 3.11843C264.705 3.11662 264.247 3.20624 263.821 3.38211C263.394 3.55798 263.006 3.81663 262.68 4.14312C262.353 4.46962 262.095 4.8575 261.919 5.28439C261.744 5.71128 261.654 6.16872 261.656 6.63033V13.4589C261.654 13.9205 261.744 14.378 261.919 14.8049C262.095 15.2317 262.353 15.6196 262.68 15.9461C263.006 16.2726 263.394 16.5313 263.821 16.7071C264.247 16.883 264.705 16.9726 265.166 16.9708H288.176C288.638 16.9726 289.095 16.883 289.522 16.7071C289.949 16.5313 290.337 16.2726 290.663 15.9461C290.989 15.6196 291.248 15.2317 291.423 14.8049C291.599 14.378 291.688 13.9205 291.686 13.4589V6.63033C291.688 6.16872 291.599 5.71128 291.423 5.28439C291.248 4.8575 290.989 4.46962 290.663 4.14312C290.337 3.81663 289.949 3.55798 289.522 3.38211C289.095 3.20624 288.638 3.11662 288.176 3.11843H265.166Z"
      fill="#293d23"
    />
    <path
      d="M268.676 43.116H245.666C244.116 43.1111 242.63 42.4928 241.534 41.3961C240.438 40.2994 239.82 38.8134 239.816 37.2629V30.4342C239.82 28.8836 240.438 27.3976 241.534 26.3009C242.63 25.2042 244.116 24.5859 245.666 24.5811H268.676C270.227 24.5858 271.713 25.2041 272.809 26.3009C273.905 27.3976 274.522 28.8836 274.526 30.4342V37.2629C274.522 38.8134 273.905 40.2994 272.809 41.3961C271.713 42.4928 270.227 43.1112 268.676 43.116ZM245.666 27.1174C245.205 27.1156 244.747 27.2052 244.321 27.3811C243.894 27.5569 243.506 27.8156 243.18 28.1421C242.853 28.4686 242.595 28.8564 242.419 29.2833C242.244 29.7102 242.154 30.1677 242.156 30.6293V37.458C242.154 37.9195 242.244 38.377 242.419 38.8039C242.595 39.2307 242.853 39.6186 243.18 39.9451C243.506 40.2716 243.894 40.5302 244.321 40.7061C244.747 40.882 245.205 40.9716 245.666 40.9698H268.676C269.138 40.9716 269.595 40.882 270.022 40.7061C270.449 40.5302 270.837 40.2716 271.163 39.9451C271.489 39.6186 271.748 39.2307 271.923 38.8039C272.099 38.377 272.188 37.9195 272.186 37.458V30.6293C272.188 30.1677 272.099 29.7102 271.923 29.2833C271.748 28.8564 271.489 28.4686 271.163 28.1421C270.837 27.8156 270.449 27.5569 270.022 27.3811C269.595 27.2052 269.138 27.1156 268.676 27.1174H245.666Z"
      fill="#293d23"
    />
    <path
      d="M296.952 43.116H286.422C284.872 43.1112 283.386 42.4928 282.29 41.3961C281.194 40.2994 280.576 38.8134 280.572 37.2629V30.4342C280.576 28.8836 281.194 27.3976 282.29 26.3009C283.386 25.2041 284.872 24.5858 286.422 24.5811H296.952C298.503 24.5858 299.988 25.2041 301.085 26.3009C302.181 27.3976 302.798 28.8836 302.802 30.4342V37.2629C302.766 38.8031 302.138 40.2703 301.048 41.36C299.959 42.4498 298.493 43.0786 296.952 43.116ZM286.422 27.1174C285.961 27.1156 285.503 27.2052 285.076 27.381C284.65 27.5569 284.262 27.8156 283.936 28.1421C283.609 28.4686 283.351 28.8564 283.175 29.2833C283 29.7102 282.91 30.1676 282.912 30.6293V37.458C282.91 37.9195 283 38.377 283.175 38.8039C283.351 39.2307 283.609 39.6186 283.936 39.9451C284.262 40.2716 284.65 40.5302 285.076 40.7061C285.503 40.882 285.961 40.9716 286.422 40.9698H296.952C297.414 40.9716 297.871 40.882 298.298 40.7061C298.725 40.5302 299.113 40.2716 299.439 39.9451C299.765 39.6186 300.024 39.2307 300.199 38.8039C300.375 38.377 300.464 37.9195 300.462 37.458V30.6293C300.464 30.1677 300.375 29.7102 300.199 29.2833C300.024 28.8564 299.765 28.4686 299.439 28.1421C299.113 27.8156 298.725 27.5569 298.298 27.3811C297.871 27.2052 297.414 27.1156 296.952 27.1174H286.422Z"
      fill="#293d23"
    />
    <path
      d="M38.0924 82.917H15.0824C13.5319 82.9121 12.0463 82.2938 10.9502 81.1971C9.85408 80.1005 9.23652 78.6146 9.23242 77.064V70.235C9.23639 68.6844 9.8539 67.1985 10.95 66.1017C12.0462 65.005 13.5318 64.3867 15.0824 64.3818H38.0924C39.643 64.3867 41.1287 65.005 42.2248 66.1017C43.3209 67.1985 43.9384 68.6844 43.9424 70.235V77.259C43.8974 78.7786 43.2606 80.2206 42.1678 81.2775C41.075 82.3344 39.6127 82.9227 38.0924 82.917ZM15.0824 66.9182C14.6208 66.9164 14.1634 67.006 13.7366 67.1819C13.3099 67.3578 12.9221 67.6164 12.5958 67.9429C12.2695 68.2694 12.011 68.6572 11.8354 69.0841C11.6597 69.511 11.5704 69.9684 11.5724 70.43V77.259C11.5704 77.7206 11.6597 78.1781 11.8354 78.605C12.011 79.0319 12.2695 79.4198 12.5958 79.7463C12.9221 80.0728 13.3098 80.3314 13.7366 80.5073C14.1634 80.6832 14.6208 80.7728 15.0824 80.771H38.0924C38.554 80.7728 39.0114 80.6832 39.4382 80.5073C39.865 80.3314 40.2527 80.0728 40.5791 79.7463C40.9054 79.4198 41.1638 79.0319 41.3395 78.605C41.5151 78.1781 41.6045 77.7206 41.6024 77.259V70.43C41.6045 69.9684 41.5151 69.511 41.3394 69.0841C41.1638 68.6573 40.9054 68.2694 40.579 67.9429C40.2527 67.6164 39.865 67.3578 39.4382 67.1819C39.0114 67.006 38.554 66.9164 38.0924 66.9182H15.0824Z"
      fill="#293d23"
    />
    <path
      d="M57.5924 107.11H34.5824C33.0319 107.105 31.5463 106.487 30.4502 105.39C29.3541 104.294 28.7365 102.808 28.7324 101.257V94.4282C28.7364 92.8776 29.3539 91.3916 30.45 90.2949C31.5462 89.1982 33.0318 88.5799 34.5824 88.5752H57.5924C59.143 88.5799 60.6287 89.1982 61.7248 90.2949C62.821 91.3916 63.4385 92.8776 63.4424 94.4282V101.257C63.4052 102.797 62.7769 104.264 61.6879 105.354C60.5989 106.443 59.1325 107.072 57.5924 107.11ZM34.5824 90.9162C34.1208 90.9144 33.6634 91.004 33.2366 91.1799C32.8098 91.3558 32.4221 91.6145 32.0958 91.941C31.7695 92.2675 31.511 92.6554 31.3354 93.0823C31.1597 93.5091 31.0704 93.9666 31.0724 94.4282V101.257C31.0704 101.719 31.1598 102.176 31.3354 102.603C31.5111 103.03 31.7696 103.418 32.0959 103.744C32.4222 104.07 32.8099 104.329 33.2367 104.505C33.6635 104.681 34.1209 104.77 34.5824 104.768H57.5924C58.054 104.77 58.5113 104.681 58.9381 104.505C59.3649 104.329 59.7526 104.07 60.079 103.744C60.4053 103.418 60.6637 103.03 60.8394 102.603C61.0151 102.176 61.1045 101.719 61.1024 101.257V94.4282C61.1045 93.9666 61.0151 93.5091 60.8395 93.0823C60.6638 92.6554 60.4054 92.2675 60.0791 91.941C59.7527 91.6145 59.365 91.3558 58.9382 91.1799C58.5114 91.004 58.054 90.9144 57.5924 90.9162H34.5824Z"
      fill="#293d23"
    />
    <path
      d="M16.8373 107.11H6.50234C4.95182 107.105 3.46624 106.487 2.37013 105.39C1.27402 104.294 0.656455 102.808 0.652344 101.257V94.4282C0.656276 92.8776 1.27378 91.3916 2.36993 90.2949C3.46608 89.1982 4.95176 88.5799 6.50234 88.5752H17.0323C18.5829 88.5799 20.0686 89.1982 21.1647 90.2949C22.2609 91.3916 22.8784 92.8776 22.8823 94.4282V101.257C22.769 102.805 22.0903 104.257 20.9755 105.336C19.8607 106.415 18.3878 107.047 16.8373 107.11ZM6.50234 90.9162C6.04073 90.9144 5.58334 91.004 5.15655 91.1799C4.72975 91.3558 4.34201 91.6145 4.0157 91.941C3.68938 92.2675 3.43095 92.6554 3.2553 93.0823C3.07966 93.5091 2.99029 93.9666 2.99234 94.4282V101.257C2.99031 101.719 3.07971 102.176 3.25537 102.603C3.43103 103.03 3.68948 103.418 4.01581 103.744C4.34213 104.07 4.72987 104.329 5.15665 104.505C5.58343 104.681 6.04079 104.77 6.50234 104.768H17.0323C17.4939 104.77 17.9513 104.681 18.378 104.505C18.8048 104.329 19.1926 104.07 19.5189 103.744C19.8452 103.418 20.1037 103.03 20.2793 102.603C20.455 102.176 20.5444 101.719 20.5423 101.257V94.4282C20.5444 93.9666 20.455 93.5091 20.2794 93.0823C20.1037 92.6554 19.8453 92.2675 19.519 91.941C19.1927 91.6145 18.8049 91.3558 18.3781 91.1799C17.9513 91.004 17.494 90.9144 17.0323 90.9162H6.50234Z"
      fill="#293d23"
    />
    <path
      d="M156.358 238C240.468 238 308.653 231.798 308.653 224.148C308.653 216.497 240.468 210.295 156.358 210.295C72.2473 210.295 4.0625 216.497 4.0625 224.148C4.0625 231.798 72.2473 238 156.358 238Z"
      fill="#293d23"
    />
    <path
      d="M125.285 185.992C122.249 185.992 119.306 184.946 116.951 183.029C114.597 181.112 112.975 178.443 112.359 175.47C111.744 172.497 112.172 169.402 113.571 166.708C114.971 164.014 117.257 161.885 120.043 160.679L120.8 160.35L103.358 81.858H77.9522C77.4614 81.8588 76.9753 81.7628 76.5217 81.5755C76.068 81.3883 75.6558 81.1134 75.3085 80.7667C74.9612 80.42 74.6856 80.0082 74.4977 79.5548C74.3097 79.1015 74.2129 78.6156 74.2129 78.1248C74.2129 77.634 74.3097 77.1481 74.4977 76.6948C74.6856 76.2414 74.9612 75.8296 75.3085 75.4829C75.6558 75.1362 76.068 74.8613 76.5217 74.6741C76.9753 74.4868 77.4614 74.3908 77.9522 74.3916H106.352C107.202 74.3874 108.028 74.6755 108.692 75.2076C109.355 75.7398 109.816 76.4838 109.996 77.315L113.555 93.3248H230.05C230.628 93.3246 231.198 93.4586 231.715 93.7163C232.232 93.9739 232.683 94.3482 233.03 94.8096C233.378 95.2709 233.614 95.8068 233.72 96.3749C233.826 96.9431 233.798 97.528 233.639 98.0835L214.705 164.351C214.48 165.13 214.009 165.814 213.362 166.302C212.715 166.791 211.927 167.056 211.116 167.058H125.299C124.545 167.056 123.799 167.203 123.102 167.49C122.405 167.776 121.771 168.198 121.237 168.73C120.703 169.261 120.28 169.893 119.99 170.589C119.701 171.285 119.551 172.031 119.55 172.785C119.549 173.538 119.697 174.285 119.985 174.981C120.273 175.678 120.695 176.311 121.228 176.844C121.76 177.377 122.393 177.8 123.089 178.088C123.785 178.377 124.532 178.525 125.285 178.525H211.116C212.106 178.525 213.056 178.918 213.756 179.618C214.456 180.319 214.85 181.268 214.85 182.258C214.85 183.248 214.456 184.198 213.756 184.898C213.056 185.598 212.106 185.992 211.116 185.992L125.285 185.992ZM128.28 159.591H208.3L225.101 100.792H115.213L128.28 159.591Z"
      fill="#081816"
    />
    <path
      d="M134.752 214.392C132.141 214.392 129.589 213.617 127.419 212.167C125.248 210.716 123.556 208.655 122.557 206.243C121.558 203.831 121.297 201.177 121.806 198.616C122.316 196.056 123.573 193.704 125.419 191.858C127.265 190.012 129.617 188.755 132.178 188.246C134.738 187.737 137.392 187.998 139.804 188.997C142.216 189.996 144.278 191.688 145.728 193.859C147.178 196.03 147.952 198.582 147.952 201.192C147.948 204.692 146.556 208.047 144.081 210.521C141.607 212.996 138.252 214.388 134.752 214.392ZM134.752 195.459C133.618 195.459 132.51 195.795 131.567 196.425C130.624 197.055 129.89 197.951 129.456 198.998C129.022 200.046 128.909 201.198 129.13 202.31C129.351 203.423 129.897 204.444 130.699 205.246C131.5 206.047 132.522 206.593 133.634 206.815C134.746 207.036 135.899 206.922 136.946 206.489C137.994 206.055 138.889 205.32 139.519 204.377C140.149 203.435 140.485 202.326 140.485 201.192C140.484 199.672 139.879 198.215 138.804 197.14C137.729 196.065 136.272 195.461 134.752 195.459Z"
      fill="#081816"
    />
    <path
      d="M134.752 213.392C132.339 213.392 129.98 212.676 127.974 211.335C125.968 209.995 124.404 208.089 123.481 205.86C122.558 203.631 122.316 201.178 122.787 198.811C123.258 196.445 124.42 194.271 126.126 192.565C127.833 190.859 130.006 189.697 132.373 189.227C134.739 188.756 137.192 188.998 139.422 189.921C141.651 190.844 143.556 192.408 144.896 194.415C146.237 196.421 146.952 198.78 146.952 201.192C146.948 204.427 145.662 207.528 143.375 209.815C141.087 212.102 137.986 213.388 134.752 213.392ZM134.752 194.459C133.42 194.459 132.119 194.854 131.012 195.594C129.904 196.334 129.041 197.385 128.532 198.616C128.022 199.846 127.889 201.2 128.149 202.506C128.409 203.812 129.05 205.011 129.992 205.953C130.933 206.894 132.133 207.536 133.439 207.795C134.745 208.055 136.099 207.922 137.329 207.412C138.559 206.903 139.611 206.04 140.35 204.933C141.09 203.826 141.485 202.524 141.485 201.192C141.483 199.407 140.773 197.696 139.511 196.433C138.249 195.171 136.537 194.461 134.752 194.459V194.459Z"
      fill="#081816"
    />
    <path
      d="M201.649 214.392C199.039 214.392 196.487 213.617 194.316 212.167C192.146 210.716 190.454 208.655 189.455 206.243C188.456 203.831 188.195 201.177 188.704 198.616C189.213 196.056 190.471 193.704 192.317 191.858C194.163 190.012 196.515 188.755 199.075 188.246C201.636 187.737 204.29 187.998 206.702 188.997C209.114 189.996 211.175 191.688 212.625 193.859C214.076 196.03 214.85 198.582 214.85 201.192C214.846 204.692 213.454 208.047 210.979 210.522C208.504 212.996 205.149 214.388 201.649 214.392ZM201.649 195.459C200.516 195.459 199.407 195.795 198.465 196.425C197.522 197.055 196.787 197.951 196.353 198.998C195.919 200.046 195.806 201.198 196.027 202.31C196.248 203.423 196.794 204.444 197.596 205.246C198.398 206.047 199.419 206.593 200.531 206.815C201.643 207.036 202.796 206.922 203.844 206.489C204.891 206.055 205.787 205.32 206.416 204.377C207.046 203.435 207.383 202.326 207.383 201.192C207.381 199.672 206.776 198.215 205.702 197.14C204.627 196.065 203.17 195.461 201.649 195.459Z"
      fill="#081816"
    />
    <path
      d="M201.649 213.392C199.237 213.392 196.878 212.676 194.872 211.335C192.866 209.995 191.302 208.089 190.379 205.86C189.455 203.631 189.214 201.178 189.685 198.811C190.156 196.445 191.318 194.271 193.024 192.565C194.73 190.859 196.904 189.697 199.27 189.227C201.637 188.756 204.09 188.998 206.319 189.921C208.548 190.844 210.453 192.408 211.794 194.415C213.134 196.421 213.85 198.78 213.85 201.192C213.846 204.427 212.559 207.528 210.272 209.815C207.985 212.102 204.884 213.388 201.649 213.392ZM201.649 194.459C200.318 194.459 199.016 194.854 197.909 195.594C196.802 196.334 195.939 197.385 195.429 198.616C194.92 199.846 194.787 201.2 195.046 202.506C195.306 203.812 195.947 205.011 196.889 205.953C197.831 206.894 199.03 207.536 200.336 207.795C201.642 208.055 202.996 207.922 204.226 207.412C205.457 206.903 206.508 206.04 207.248 204.933C207.988 203.826 208.383 202.524 208.383 201.192C208.381 199.407 207.671 197.696 206.408 196.433C205.146 195.171 203.435 194.461 201.649 194.459V194.459Z"
      fill="#081816"
    />
    <path
      d="M155.372 134.947C156.476 134.943 157.555 134.611 158.47 133.993C159.386 133.376 160.098 132.5 160.516 131.477C160.934 130.455 161.039 129.331 160.818 128.249C160.596 127.167 160.059 126.175 159.273 125.398C158.488 124.622 157.49 124.096 156.405 123.887C155.32 123.678 154.198 123.796 153.18 124.225C152.163 124.655 151.295 125.376 150.688 126.299C150.081 127.222 149.761 128.304 149.77 129.409C149.788 130.883 150.386 132.291 151.434 133.328C152.483 134.364 153.897 134.946 155.372 134.947Z"
      fill="#081816"
    />
    <path
      d="M157.135 129.462C157.554 129.461 157.964 129.335 158.312 129.1C158.66 128.865 158.93 128.533 159.089 128.144C159.248 127.756 159.288 127.329 159.204 126.918C159.12 126.506 158.916 126.129 158.617 125.834C158.319 125.539 157.939 125.339 157.527 125.26C157.115 125.181 156.689 125.225 156.302 125.389C155.915 125.552 155.586 125.826 155.355 126.177C155.124 126.527 155.003 126.938 155.006 127.358C155.013 127.918 155.24 128.453 155.638 128.847C156.037 129.241 156.574 129.462 157.135 129.462Z"
      fill="#F8FBFE"
    />
    <path
      d="M153.135 133.625C153.378 133.624 153.615 133.551 153.817 133.415C154.018 133.279 154.175 133.087 154.266 132.862C154.358 132.637 154.381 132.39 154.333 132.152C154.284 131.914 154.166 131.695 153.993 131.524C153.82 131.354 153.601 131.238 153.362 131.192C153.123 131.146 152.877 131.172 152.653 131.267C152.429 131.361 152.238 131.52 152.104 131.723C151.971 131.926 151.901 132.164 151.902 132.407C151.906 132.731 152.038 133.041 152.268 133.269C152.499 133.497 152.811 133.625 153.135 133.625Z"
      fill="#F8FBFE"
    />
    <path
      d="M184.725 134.728C185.83 134.724 186.908 134.392 187.824 133.774C188.74 133.156 189.452 132.281 189.87 131.258C190.288 130.235 190.393 129.112 190.171 128.03C189.95 126.947 189.413 125.955 188.627 125.178C187.842 124.402 186.843 123.876 185.759 123.667C184.674 123.458 183.552 123.576 182.534 124.006C181.516 124.435 180.649 125.157 180.042 126.08C179.434 127.003 179.115 128.085 179.123 129.189C179.141 130.664 179.739 132.072 180.787 133.108C181.836 134.145 183.251 134.727 184.725 134.728Z"
      fill="#081816"
    />
    <path
      d="M186.487 129.242C186.907 129.241 187.317 129.115 187.665 128.88C188.013 128.645 188.283 128.313 188.442 127.924C188.601 127.535 188.641 127.108 188.557 126.697C188.473 126.286 188.269 125.909 187.97 125.614C187.672 125.318 187.292 125.119 186.88 125.039C186.468 124.96 186.041 125.005 185.654 125.168C185.268 125.331 184.938 125.606 184.707 125.956C184.477 126.307 184.355 126.718 184.358 127.138C184.365 127.698 184.592 128.233 184.991 128.627C185.389 129.021 185.927 129.242 186.487 129.242Z"
      fill="#F8FBFE"
    />
    <path
      d="M182.485 133.403C182.729 133.402 182.966 133.329 183.167 133.193C183.369 133.057 183.525 132.864 183.617 132.64C183.709 132.415 183.732 132.167 183.684 131.929C183.635 131.691 183.517 131.473 183.344 131.302C183.171 131.131 182.951 131.015 182.713 130.969C182.474 130.923 182.227 130.949 182.003 131.044C181.779 131.138 181.589 131.297 181.455 131.5C181.321 131.703 181.251 131.941 181.253 132.184C181.257 132.509 181.388 132.819 181.619 133.047C181.85 133.275 182.161 133.403 182.485 133.403Z"
      fill="#F8FBFE"
    />
    <path
      d="M153.861 120.001C152.458 120.042 151.069 119.706 149.84 119.027C149.574 118.879 149.377 118.632 149.292 118.34C149.208 118.047 149.243 117.733 149.389 117.466C149.536 117.199 149.782 117.001 150.075 116.916C150.367 116.83 150.681 116.864 150.948 117.01C152.318 117.647 153.861 117.811 155.334 117.477C156.807 117.142 158.128 116.328 159.088 115.162C159.19 115.058 159.311 114.976 159.445 114.919C159.579 114.862 159.722 114.832 159.868 114.831C160.013 114.83 160.157 114.858 160.291 114.912C160.426 114.967 160.548 115.048 160.652 115.15C160.755 115.252 160.837 115.373 160.893 115.507C160.949 115.641 160.979 115.785 160.979 115.93C160.98 116.075 160.952 116.219 160.896 116.354C160.841 116.488 160.76 116.61 160.657 116.713C159.827 117.717 158.79 118.53 157.618 119.098C156.445 119.665 155.164 119.973 153.861 120.001Z"
      fill="#081816"
    />
    <path
      d="M184.55 119.656C183.34 119.659 182.142 119.417 181.028 118.946C179.914 118.475 178.906 117.784 178.066 116.914C177.873 116.693 177.776 116.404 177.795 116.111C177.815 115.818 177.95 115.545 178.171 115.351C178.392 115.158 178.68 115.06 178.973 115.078C179.266 115.097 179.54 115.231 179.734 115.451C180.785 116.539 182.168 117.244 183.666 117.456C185.163 117.667 186.688 117.371 187.998 116.616C188.129 116.557 188.271 116.524 188.415 116.52C188.56 116.515 188.703 116.539 188.838 116.59C188.973 116.642 189.097 116.719 189.202 116.818C189.307 116.916 189.391 117.035 189.45 117.167C189.509 117.298 189.542 117.44 189.546 117.584C189.551 117.728 189.526 117.872 189.475 118.007C189.424 118.142 189.347 118.265 189.248 118.37C189.149 118.475 189.03 118.559 188.898 118.618C187.542 119.277 186.058 119.631 184.55 119.656Z"
      fill="#081816"
    />
    <path
      d="M176.869 152.644C176.651 152.663 176.434 152.609 176.251 152.488C176.068 152.368 175.931 152.19 175.862 151.983C175.441 150.998 174.739 150.158 173.843 149.57C172.948 148.981 171.899 148.669 170.827 148.672C169.809 148.682 168.814 148.971 167.949 149.509C167.085 150.047 166.386 150.813 165.928 151.722C165.871 151.855 165.788 151.975 165.684 152.075C165.58 152.176 165.458 152.255 165.323 152.308C165.189 152.361 165.046 152.387 164.901 152.384C164.757 152.381 164.615 152.35 164.482 152.293C164.35 152.235 164.23 152.152 164.13 152.048C164.03 151.944 163.951 151.821 163.899 151.687C163.846 151.552 163.821 151.409 163.823 151.264C163.826 151.12 163.858 150.978 163.916 150.845C164.533 149.535 165.509 148.425 166.731 147.646C167.952 146.866 169.37 146.448 170.819 146.44C172.327 146.399 173.811 146.821 175.072 147.65C176.332 148.478 177.308 149.674 177.868 151.075C177.985 151.341 177.992 151.643 177.889 151.915C177.786 152.187 177.579 152.408 177.315 152.529C177.18 152.609 177.026 152.648 176.869 152.644Z"
      fill="#081816"
    />
    <path
      d="M171.554 26.9891C171.554 25.8906 170.664 25 169.565 25C168.467 25 167.576 25.8906 167.576 26.9891V60.4456C167.576 61.5442 168.467 62.4348 169.565 62.4348C170.664 62.4348 171.554 61.5442 171.554 60.4456V26.9891Z"
      fill="#081816"
    />
    <path
      d="M210.488 46.8709C211.264 46.0941 211.264 44.8347 210.488 44.0579C209.711 43.2811 208.451 43.2811 207.675 44.0579L184.017 67.7152C183.24 68.492 183.24 69.7515 184.017 70.5283C184.794 71.3051 186.053 71.3051 186.83 70.5283L210.488 46.8709Z"
      fill="#081816"
    />
    <path
      d="M131.733 42.2234C130.956 41.4466 129.697 41.4465 128.92 42.2234C128.143 43.0002 128.143 44.2596 128.92 45.0364L152.578 68.6937C153.354 69.4705 154.614 69.4705 155.391 68.6937C156.167 67.9169 156.167 66.6575 155.391 65.8807L131.733 42.2234Z"
      fill="#081816"
    />
  </svg>
);

export default Icon;
