import React from "react";
import { NavLink } from "react-router-dom";

import { Toolbar, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";

import TiktokIcon from "assets/tiktok-icon";
import GoodreadsIcon from "assets/goodreads-icon";
import EtsyIcon from "assets/etsy-icon";
import { FooterEl } from "elements/common";

const socials = [
  {
    key: "facebook",
    icon: <FacebookIcon />,
    linkTo: "https://www.facebook.com/gtmatadv",
  },
  {
    key: "youtube",
    icon: <YouTubeIcon />,
    linkTo: "https://www.facebook.com/gtmatadv",
  },
  {
    key: "instagram",
    icon: <InstagramIcon />,
    linkTo: "https://www.facebook.com/gtmatadv",
  },
  {
    key: "pinterest",
    icon: <PinterestIcon />,
    linkTo: "https://www.facebook.com/gtmatadv",
  },
  {
    key: "tiktok",
    icon: <TiktokIcon />,
    linkTo: "https://www.facebook.com/gtmatadv",
  },
  {
    key: "goodreads",
    icon: <GoodreadsIcon />,
    linkTo: "https://www.goodreads.com/user/show/121212457-prav-tadikonda",
  },
  {
    key: "etsy",
    icon: <EtsyIcon />,
    linkTo: "https://goldenleafbindery.etsy.com",
  },
];

const Footer = () => (
  <FooterEl>
    <Toolbar>
      {socials.map((social) => (
        <IconButton
          key={social.key}
          color="inherit"
          aria-label="open drawer"
          style={{ padding: 0 }}
        >
          <NavLink
            to={social.linkTo}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "currentColor", height: "1em", padding: "8px" }}
          >
            {social.icon}
          </NavLink>
        </IconButton>
      ))}
    </Toolbar>
  </FooterEl>
);

export default Footer;
