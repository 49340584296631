import React from "react";

import { Typography } from "@mui/material";
import { theme } from "theme.main";

type BadgeProps = {
  text: string;
  color?: string;
  className?: string;
};

/**
 *
 * @param text text inside of badge
 * @param color color of badge
 * @returns
 */
const Badge = ({ text, color = "#F34141", className }: BadgeProps) => (
  <Typography
    className={className}
    variant="body2"
    textAlign="left"
    fontWeight="bold"
    fontSize="12px"
    width="fit-content"
    padding="5px"
    color={theme.palette.common.white}
    lineHeight={1}
    marginBottom="5px"
    style={{ background: color }}
  >
    {text}
  </Typography>
);

export default Badge;
