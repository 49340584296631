import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import frontMatterFormatter from "gray-matter";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import _ from "lodash";

import { Box, Paper, Skeleton, Typography } from "@mui/material";

import { getPosts } from "api/blog";
import { MainLayout as Layout } from "components/layout";
import { HEADER_HEIGHT, FOOTER_HEIGHT } from "constants/index";
import { Flex } from "elements/common";
import { NavigatePosts } from "elements/blog";
import { BlogPostMiniProps, ImportedBlogPost } from "types";
import { theme } from "theme.main";
import "blog.css";

const BlogHeroImage = () => (
  <Box
    sx={{
      backgroundImage: "url(/library.jpg)",
      backgroundSize: "cover",
      flexGrow: 1,
    }}
  >
    <Flex
      minHeight={[
        `calc(100vh - ${HEADER_HEIGHT})`,
        `calc(100vh - ${HEADER_HEIGHT})`,
        "auto",
      ]}
      height="100%"
      alignItems="flex-end"
    >
      <Typography
        color="white"
        fontFamily="Blackjack, cursive"
        variant="h1"
        sx={{
          fontSize: ["4.5rem", "5.5rem"],
          padding: "3rem 1rem",
          lineHeight: 1,
        }}
      >
        Weekly Blog
      </Typography>
    </Flex>
  </Box>
);

const BlogPostMini = ({
  post: {
    slug,
    data: { title, description, pub_date },
  },
}: BlogPostMiniProps) => {
  const navigate = useNavigate();

  return (
    <Paper
      key={slug}
      elevation={0}
      sx={{
        color: "#EEE8DF",
        background: "inherit",
        padding: "1rem",
        borderRadius: 0,
        marginBottom: "1rem",
        maxWidth: "600px",
        textAlign: "left",
        cursor: "pointer",
        margin: "0.5rem 1rem",
        "&:hover": { boxShadow: theme.shadows[9] },
      }}
      onClick={() => navigate(`/blog/posts/${slug}/`)}
    >
      <Typography variant="body2">{moment(pub_date).format("LL")}</Typography>
      <Typography
        variant="h5"
        fontWeight={500}
        sx={{
          "&:hover": { textDecoration: "underline" },
        }}
      >
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Paper>
  );
};

const BlogPost = () => {
  const [blogPost, setBlogPost] = useState<ImportedBlogPost>();
  const { blogPostingTitle } = useParams();

  useEffect(() => {
    import(`components/posts/${blogPostingTitle}.md`).then((res) => {
      fetch(res.default)
        .then((response) => response.text())
        .then((response) => {
          const parsed_post = frontMatterFormatter(response);

          setBlogPost({
            slug: blogPostingTitle,
            content: parsed_post.content,
            data: parsed_post.data,
          } as ImportedBlogPost);
        })
        .catch((err) => console.log(err));
    });
  }, [blogPostingTitle]);

  if (!blogPost) return;

  return (
    <Layout bgColor={theme.palette.common.white}>
      <Flex justifyContent="center" className="blog-post">
        <Flex
          justifyContent="center"
          textAlign="left"
          lineHeight="2rem"
          fontSize="1.125rem"
          fontFamily="Montserrat Variable"
          fontWeight={500}
          padding="1em"
          maxWidth={["calc(100% - 2em)", "calc(100% - 2em)", "800px"]}
          flexDirection="column"
        >
          <h1>{blogPost.data.title}</h1>
          <p className="date">
            {moment(blogPost.data.pub_date).format("MMMM Do, YYYY")}
          </p>

          {blogPost.content && <Markdown>{blogPost.content}</Markdown>}

          <NavigatePosts justifyContent="center">
            {blogPost.data.prev && (
              <Link to={`/blog/posts/${blogPost.data.prev}/`}>
                ← Previous post
              </Link>
            )}
            {blogPost.data.prev && blogPost.data.next && (
              <Box margin="0 1rem">•</Box>
            )}
            {blogPost.data.next && (
              <Link to={`/blog/posts/${blogPost.data.next}/`}>Next post →</Link>
            )}
          </NavigatePosts>
        </Flex>
      </Flex>
    </Layout>
  );
};

const Blog = () => {
  const [posts, setPosts] = useState<ImportedBlogPost[]>();

  useEffect(() => {
    getPosts().then((imported_posts) => {
      setPosts(imported_posts);
    });
  }, []);

  return (
    <Layout bgColor={theme.palette.primary.light}>
      <Flex
        flexDirection={["column", "column", "row"]}
        height={[
          "auto",
          "auto",
          `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`,
        ]}
      >
        <BlogHeroImage />
        <Flex
          flexDirection="column"
          alignItems="center"
          width={["100%", "100%", "50%"]}
          overflow="scroll"
        >
          <Flex flexDirection="column" margin="1rem">
            {posts
              ? posts.map((post) => {
                  return <BlogPostMini key={post.slug} post={post} />;
                })
              : _.times(5, (x) => (
                  <Box key={x} marginBottom="2rem">
                    {_.times(3, (x) => (
                      <Skeleton
                        key={x}
                        animation="wave"
                        sx={{
                          maxWidth: "600px",
                          width: ["80vw", "80vw", "42vw"],
                          height: "35px",
                          borderRadius: 0,
                        }}
                      />
                    ))}
                  </Box>
                ))}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export { Blog, BlogPost };
