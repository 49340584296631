import styled from "@emotion/styled";
import { theme } from "theme.main";

export const HeroImg = styled.img`
  width: 110px;
  &.left-img {
    transform: rotate(90deg) translateX(85px);
  }
  &.right-img {
    transform: rotate(90deg) translateX(-85px);
  }
  transition: all 250ms ease-in-out;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    display: flex;
    width: 100px;

    &.left-img {
      transform: translateX(35px);
    }
    &.right-img {
      transform: translateX(-35px);
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 150px;
  }
`;
