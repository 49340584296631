import { createTheme } from "@mui/material/styles";

// color theme: https://coolors.co/261714-400d0d-eee8df-719894-142624

export const theme = createTheme({
  palette: {
    // primary: {
    //   main: "#261714", // dark brown
    //   light: "#735E59",
    //   dark: "#160B09",
    // },
    primary: {
      main: "#400D0D", // dark maroon
      light: "#8B4E4E",
      dark: "#2B0606",
    },
    secondary: {
      main: "#293d23", // army green
      light: "#818B7E",
      dark: "#081816",
      contrastText: "#EEE8DF",
    },
    error: {
      main: "#A43131",
      light: "#D9ABAB",
      dark: "#6E2828",
    },
    success: { main: "#427C76", dark: "#142624", light: "#A2B7B5" },
    common: {
      black: "#222",
      white: "#EEE8DF",
    },
    background: {
      default: "#EEE8DF",
      paper: "#EEE8DF",
    },
    action: {
      hover: "#DCD4C9",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === "error" && {
            backgroundColor: "#D9ABAB",
            color: "#6E2828",
          }),
          ...(ownerState.severity === "success" && {
            backgroundColor: "#A2B7B5",
            color: "#142624",
          }),
        }),
      },
    },
  },
  typography: {
    h3: {
      fontSize: "2.8rem",
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 500,
    },
    button: {
      textTransform: "none",
    },
    fontFamily: "'Montserrat Variable', sans-serif",
  },
});
