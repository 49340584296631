import React from "react";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import ImageBuilder from "components/image-builder";
import { Flex } from "elements/common";
import { MediaDisplayProps } from "types";
import { IMAGE_CAROUSEL_ITEM_WIDTH } from "constants/index";
import { ImageContainer } from "elements/listing";
import { theme } from "theme.main";

const MediaDisplay = ({
  mediaList,
  currentMediaKey,
  selectMedia,
}: MediaDisplayProps) => (
  <Flex flexDirection="column" display={["none", "flex"]}>
    {mediaList.map((bookMedia) => (
      <ImageContainer
        className={`${currentMediaKey === bookMedia.key ? "selected" : ""}`}
        key={bookMedia.key}
        onClick={() => selectMedia(bookMedia)}
      >
        {bookMedia.resource_type === "video" && (
          <PlayArrowIcon
            sx={{
              position: "absolute",
              width: "2em",
              height: "auto",
              background: theme.palette.common.white,
              borderRadius: "50%",
            }}
          />
        )}

        <ImageBuilder
          resourceType={bookMedia.resource_type}
          fileName={bookMedia.src}
          width={IMAGE_CAROUSEL_ITEM_WIDTH}
          roundedEdges
          shouldAutoplay={false}
        />
      </ImageContainer>
    ))}
  </Flex>
);

export default MediaDisplay;
