import { AppBar, Button, Box } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "theme.main";
import { HEADER_HEIGHT } from "constants/index";

export const NavItem = styled(Button)`
  border-radius: 0;
  padding: 0.5rem 0.75rem;
`;

export const NavDivider = styled(Box)`
  background: black;
  width: 2px;
`;

export const Header = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${theme.palette.common.white};
  padding: 0 1rem;
  z-index: 3;
  height: ${HEADER_HEIGHT};

  &.header {
    box-shadow: none;
    border-bottom: 1px #cdcdcd solid;
  }

  .profile-icon svg {
    fill: none;
  }

  .shopping-cart svg {
    fill: rgba(0, 0, 0, 0.84);
  }
`;

export const MobileMenu = styled(Box)`
  &.mobile-menu {
    position: fixed;
    top: ${HEADER_HEIGHT};
    z-index: 3;
    transition: all 300ms ease-in-out;
  }
`;
