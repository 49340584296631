import React from "react";

import { Button, Divider } from "@mui/material";

import GoogleIcon from "assets/google-icon";
import FacebookIcon from "assets/facebook-icon";
import { Flex } from "elements/common";
import { theme } from "theme.main";

const SocialLogins = () => (
  <>
    <Divider sx={{ marginTop: "2rem", width: ["100%", "500px"] }}>or</Divider>

    <Flex
      flexDirection="column"
      justifyContent="space-around"
      width="275px"
      marginTop="2rem"
      sx={{
        button: {
          fontWeight: theme.typography.fontWeightBold,
          borderRadius: "2rem",
          marginBottom: "1rem",
        },
      }}
    >
      <Button variant="outlined" startIcon={<GoogleIcon />}>
        Google
      </Button>
      <Button variant="outlined" startIcon={<FacebookIcon />}>
        Facebook
      </Button>
    </Flex>
  </>
);

export default SocialLogins;
