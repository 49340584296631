import React from "react";

import Filter from "components/filter";
import { Listing } from "components/cards";
import { MainLayout as Layout, TitleLayout } from "components/layout";
import { Flex, Grid } from "elements/common";

// API call needed to get listings -- use variables for now
// find a better way to add in discounts
import { fakeListings } from "tests/fake-returns";
import { ListingType } from "types/api";
import { theme } from "theme.main";

const Shop = () => (
  <Layout bgColor={theme.palette.secondary.light}>
    <TitleLayout title="Shop" fullPage>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        margin={[0, 0, 0, "0 3rem"]}
      >
        <Filter />

        <Grid
          gridTemplateColumns="repeat(auto-fill, minmax(282px, 1fr))"
          width="100%"
          justifyItems="center"
        >
          {(fakeListings as ListingType[]).map((listing) => (
            <Listing
              key={listing.key}
              bookID={listing.key}
              title={listing.title}
              subtitle={`$${listing.price}`}
              badge={listing.discount}
              favorite={listing.favorite}
              // TODO: needs a better way to pick primary
              primaryMedia={listing.mediaList[0]}
              secondaryMedia={listing.mediaList[1]}
            />
          ))}
        </Grid>
      </Flex>
    </TitleLayout>
  </Layout>
);

export default Shop;
