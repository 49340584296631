import React, { useState } from "react";

import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MediaWrapper from "components/image-builder/media-wrapper";
import { AnimatedPaper, Flex } from "elements/common";
import { theme } from "theme.main";
import { CartItemProps } from "types";

const MiniProductView = ({ item, idx, handleRemoveItem }: CartItemProps) => {
  const [removeItem, setRemoveItem] = useState<boolean>(false);

  const handleDelete = () => {
    handleRemoveItem(idx);
    setRemoveItem(true);
  };

  return (
    <AnimatedPaper
      className={removeItem ? " disappear" : ""}
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "1rem",
        borderRadius: 0,
        padding: "1rem",
        height: "130px",
        maxWidth: "375px",
        "&:hover": { boxShadow: theme.shadows[2] },
      }}
      elevation={5}
    >
      <MediaWrapper
        title={item.title}
        primaryMedia={item.mediaList[0]}
        secondaryMedia={item.mediaList[1]}
        sx={{
          maxWidth: "130px",
          marginRight: "2rem",
          img: {
            width: "100%",
            height: "auto",
          },
        }}
      />
      <Flex
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        minWidth={["150px", "225px"]}
        position="relative"
      >
        <Flex flexDirection="column" alignItems="flex-start">
          <Link
            underline="none"
            variant="h5"
            target="_blank"
            rel="noopener, noreferrer"
            href={`/shop/${item.key}`}
            color={theme.palette.common.black}
            sx={{
              cursor: "pointer",
              fontSize: "1.25rem",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {item.title}
          </Link>
          <Typography>${item.price}</Typography>
        </Flex>
        <Tooltip title="Remove from Cart" arrow>
          <IconButton
            onClick={handleDelete}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Flex>
    </AnimatedPaper>
  );
};

export default MiniProductView;
