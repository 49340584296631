import React from "react";

import { BackDrop } from "elements/common";

type MaskProps = {
  openDrawer: boolean;
  handleToggleDrawer: () => void;
};

const Mask = ({ openDrawer, handleToggleDrawer }: MaskProps) => {
  return (
    <BackDrop
      display={[openDrawer ? "block" : "none", "none", "none", "none"]}
      className="mask"
      onClick={handleToggleDrawer}
    />
  );
};

export default Mask;
