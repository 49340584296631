import React, { useState } from "react";

import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";

import {
  FilterTitleProps,
  OpenFiltersButtonProps,
  PriceFilterProps,
} from "types";
import { FilterHeader } from "elements/filter";
import { MAX_PRICE, MIN_PRICE, MIN_PRICE_RANGE } from "constants/index";

const OpenFiltersButton = ({ handleOnClick }: OpenFiltersButtonProps) => (
  <Button
    variant="contained"
    color="secondary"
    startIcon={<TuneIcon />}
    onClick={handleOnClick}
    style={{
      fontSize: "16px",
      fontWeight: 600,
      borderRadius: "50px",
      margin: "0 1.5rem",
    }}
  >
    Filters
  </Button>
);

const PriceFilter = ({ priceRange, setPriceRange }: PriceFilterProps) => {
  const handlePriceRangeChange = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceRange([
        Math.min(newValue[0], priceRange[1] - MIN_PRICE_RANGE),
        priceRange[1],
      ]);
    } else {
      setPriceRange([
        priceRange[0],
        Math.max(newValue[1], priceRange[0] + MIN_PRICE_RANGE),
      ]);
    }
  };

  return (
    <>
      <Typography variant="body1" fontWeight={600}>
        Price Range
      </Typography>
      <Typography variant="body2" marginBottom="2.5rem">
        ${priceRange[0]} - ${priceRange[1]}
      </Typography>
      <Slider
        getAriaLabel={() => "Minimum Price Range"}
        value={priceRange}
        onChange={handlePriceRangeChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`}
        getAriaValueText={() => "Expensive"}
        disableSwap
      />
    </>
  );
};

const FilterTitle = ({ setOpen }: FilterTitleProps) => (
  <FilterHeader>
    <IconButton
      onClick={() => setOpen(false)}
      style={{ position: "absolute", top: "0.25rem", left: "0.5rem" }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h3" fontFamily="Blackjack, cursive">
      Filters
    </Typography>
  </FilterHeader>
);

const Filter = () => {
  const [open, setOpen] = useState(false);
  const [priceRange, setPriceRange] = useState<number[]>([
    MIN_PRICE,
    MAX_PRICE,
  ]);

  const handleApplyFilters = () => {
    // make api call and next search (or filter recoil?)
    setOpen(false);
  };

  return (
    <>
      <OpenFiltersButton handleOnClick={() => setOpen(true)} />
      <Drawer open={open} onClose={() => setOpen(false)}>
        <FilterTitle setOpen={setOpen} />
        <Divider />

        <List style={{ margin: "1.5rem" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <PriceFilter
              priceRange={priceRange}
              setPriceRange={setPriceRange}
            />
          </ListItem>

          {/* Input more filters here with ListItem */}
        </List>
        <Button
          variant="contained"
          sx={{ margin: "1rem 2rem" }}
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
      </Drawer>
    </>
  );
};

export default Filter;
