
/**
 * Using webpack, put in a require context (list of files names within src), 
 * and output the name of the static source file
 * 
 * importAll(["components/posts/first-blog-post.md"]) => "/static/media/first-blog-post.6ae74a424eac3b34824f.md"
 * 
 * @param r webpack's require context
 * @returns name of static file
 */
export const importAll = (r: __WebpackModuleApi.RequireContext) => r.keys().map(r);

/**
 * Given a encoded static file, return the file name as a slug
 * 
 * "/static/media/first-blog-post.6ae74a424eac3b34824f.md" => "first-blog-post"
 * 
 * @param static_file name of static file
 * @returns file name as slug
 */
export const getSlug = (static_file: string) => {
  const slug = static_file.match(/\/[a-z-]+\./g);

  if (!slug) return "";
  return slug[0].replace(/\/|\./g, "");
};