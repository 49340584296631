import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";

import { GOODREADS_QUOTES_SCRIPT } from "constants/index";
import { MainLayout as Layout } from "components/layout";
import { Flex } from "elements/common";
import { useScript } from "hooks";
import { theme } from "theme.main";
import { HeroImg } from "elements/home";
import { ReviewBar } from "components/lists";

const TitleBox = () => (
  <Flex
    height="400px"
    justifyContent="center"
    alignItems="center"
    fontFamily={"Cinzel Decorative"}
    color={theme.palette.primary.main}
    bgcolor="#5b4352"
    flexDirection={["column", "row"]}
    sx={{
      backgroundImage: "url(/home-background.png)",
      backgroundSize: "cover",
    }}
  >
    <HeroImg src="/leaf-upward.png" className="left-img" />
    <Box
      bgcolor="rgba(238, 232, 223, 0.7)"
      border={`5px solid ${theme.palette.primary.main}`}
      padding={["1.5rem", "1rem", "4rem"]}
      fontSize={["45px", "50px", "65px"]}
      borderRadius="6px"
      textTransform="uppercase"
      sx={{
        animation: "fade-in 1s ease-in-out",
        "@keyframes fade-in": { from: { opacity: 0 }, to: { opacity: 1 } },
      }}
    >
      Golden Leaf
      <br />
      Bindery
    </Box>
    <HeroImg src="/leaf-downward.png" className="right-img" />
  </Flex>
);

const ShopInquiry = () => {
  const navigate = useNavigate();

  return (
    <Flex bgcolor={theme.palette.primary.light} justifyContent="center">
      <Flex
        height={["auto", "auto", "350px"]}
        alignItems="center"
        maxWidth={theme.breakpoints.values.lg}
        width="100vw"
        flexDirection={["column-reverse", "column-reverse", "row"]}
      >
        <Flex
          flexDirection={["column-reverse", "column-reverse", "row"]}
          height="100%"
          flexBasis={["auto", "auto", "50%"]}
          alignItems="center"
          justifyContent="center"
          padding={["1rem", "1rem", 0]}
          color={theme.palette.common.white}
          textAlign={["center", "center", "left"]}
        >
          <img src="/full-logo.png" width="275px" alt="logo" />
          <Flex flexDirection="column" marginLeft={[0, 0, "1rem"]}>
            <Typography
              fontSize="1.125rem"
              width={["325px", "350px"]}
              marginBottom="1rem"
            >
              For centuries, the golden leaf has been utilized to enhance the
              aesthetics of various objects.
            </Typography>
            <Typography width={["325px", "350px"]} fontSize="1.125rem">
              My products will not feature golden leaves (yet). However, I
              strive to preserve the beauty of this ancient tradition
            </Typography>
          </Flex>
        </Flex>

        <Flex
          flexBasis={["auto", "auto", "50%"]}
          alignItems="center"
          justifyContent="center"
          marginTop={["2rem", "2rem", 0]}
        >
          <Button
            onClick={() => navigate("/shop/")}
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "1.125rem",
            }}
          >
            Shop now!
          </Button>{" "}
        </Flex>
      </Flex>
    </Flex>
  );
};

const QuoteBox = () => {
  useScript(GOODREADS_QUOTES_SCRIPT);

  return (
    <Flex
      bgcolor={theme.palette.secondary.light}
      justifyContent="center"
      padding="1rem 0.5rem"
    >
      <Flex
        padding="0px 7px"
        maxWidth="800px"
        minWidth="350px"
        marginTop="2rem"
        alignSelf="center"
        flexDirection="column"
      >
        <Box id="gr_quote_body" textAlign="left" fontStyle="italic">
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        </Box>
        <Box textAlign="right">
          <Link
            href="https://www.goodreads.com/quotes"
            rel="nofollow"
            color="#382110"
            target="_blank"
            underline="none"
            fontSize="10px"
          >
            Goodreads Quotes
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
};

const SpecialRequests = () => {
  const navigate = useNavigate();

  return (
    <Flex justifyContent="center" bgcolor={theme.palette.primary.light}>
      <Flex
        height="350px"
        alignItems="center"
        justifyContent="center"
        maxWidth={theme.breakpoints.values.md}
        width="100vw"
        flexDirection={["column-reverse", "column-reverse", "row"]}
      >
        <Flex
          flexBasis={["auto", "auto", "50%"]}
          alignItems="center"
          justifyContent="center"
          margin={["2rem", "2rem", 0]}
        >
          <Button
            onClick={() => navigate("/support/")}
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "1.125rem",
            }}
          >
            Special Request!
          </Button>
        </Flex>
        <Flex
          height={["auto", "auto", "100%"]}
          flexBasis={["auto", "auto", "50%"]}
          alignItems="center"
          justifyContent="center"
          padding={["1rem", "1rem", 0]}
        >
          <Typography
            textAlign="left"
            width={["325px", "350px"]}
            fontSize="1.125rem"
            color={theme.palette.common.white}
          >
            Would you like a book that's not currently listed? Contact me and we
            can work something out!
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Home = () => (
  <Layout bgColor={theme.palette.common.white}>
    <Flex flexDirection="column">
      <TitleBox />
      <ShopInquiry />
      <QuoteBox />
      <SpecialRequests />
      <ReviewBar />
      {/* Gallary of all products */}
      {/* Poll of favorite books */}
    </Flex>
  </Layout>
);

export default Home;
