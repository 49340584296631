import styled from "@emotion/styled";
import { loadingButtonClasses } from "@mui/lab";

type FormProps = {
  fixedWidth?: boolean;
};

export const Form = styled.form<FormProps>(({ fixedWidth = false }) => ({
  display: "flex",
  flexDirection: "column",
  width: fixedWidth ? "80vw" : "auto",
  maxWidth: fixedWidth ? "400px" : "none",

  [`.${loadingButtonClasses.loadingIndicator}`]: {
    position: "static",
    margin: "0 1rem 0 -2rem",
  },
}));
