import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";

import { MainLayout as Layout, TitleLayout } from "components/layout";
import { EMAIL_REGEX } from "constants/index";
import { Flex } from "elements/common";
import { Form } from "elements/forms";
import { LoginInputType, LoginFormType } from "types";
import { theme } from "theme.main";
import SocialLogins from "components/social-logins";
import ButtonLink from "components/buttons/link";

const EmailInput = ({ errors, register }: LoginInputType) => (
  <FormControl error={Boolean(errors.email)} margin="normal">
    <InputLabel htmlFor="component-outlined">Email</InputLabel>
    <OutlinedInput
      id="component-outlined"
      label="Email"
      {...register("email", {
        required: true,
        pattern: EMAIL_REGEX,
      })}
    />
    {errors.email?.type === "required" && (
      <FormHelperText id="component-error-text">
        An email is required
      </FormHelperText>
    )}
    {errors.email?.type === "pattern" && (
      <FormHelperText id="component-error-text">
        Enter a valid email
      </FormHelperText>
    )}
  </FormControl>
);

const PasswordInput = ({ errors, register }: LoginInputType) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <FormControl error={Boolean(errors.password)} margin="normal">
      <InputLabel htmlFor="component-outlined">Password</InputLabel>
      <OutlinedInput
        id="component-outlined"
        label="Password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((show) => !show)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...register("password", { required: true })}
      />
      {errors.password && (
        <FormHelperText id="component-error-text">
          A password is required
        </FormHelperText>
      )}
    </FormControl>
  );
};

const LogIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleLogIn = (loginParams: LoginFormType) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 400);
  };

  return (
    <Layout>
      <TitleLayout title="Welcome Back!">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Form onSubmit={handleSubmit(handleLogIn)} fixedWidth>
            <EmailInput errors={errors} register={register} />
            <PasswordInput errors={errors} register={register} />

            <Flex justifyContent="space-between">
              <FormControlLabel control={<Checkbox />} label="Remember me" />
              <ButtonLink
                handleOnClick={() => navigate("/reset-password/")}
                text="Forgot Password"
              />
            </Flex>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              size="large"
              type="submit"
              sx={{
                borderRadius: "2rem",
                fontSize: "1.1em",
                borderWidth: "medium",
                marginTop: "1rem",
                width: "250px",
                alignSelf: "center",
              }}
            >
              Sign In
            </LoadingButton>
          </Form>

          <SocialLogins />
          <Typography marginTop="2rem">
            Don't have an account?
            <Link
              onClick={() => navigate("/sign-up/")}
              sx={{
                padding: "0.55rem",
                alignSelf: "center",
                textDecoration: "none",
                fontWeight: "600",
                cursor: "pointer",
                "&:hover": { color: theme.palette.primary.dark },
              }}
            >
              Sign Up
            </Link>
          </Typography>
        </Flex>
      </TitleLayout>
    </Layout>
  );
};

export default LogIn;
