import styled from "@emotion/styled";
import { Box, IconButton, Paper } from "@mui/material";
import { Flex } from "./common";
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  IMAGE_CAROUSEL_ITEM_WIDTH,
  IMAGE_CAROUSEL_ITEM_BORDER,
} from "constants/index";
import { theme } from "theme.main";

export const ListingCard = styled(Paper)`
  height: ${CARD_HEIGHT}px;
  width: ${CARD_WIDTH}px;
  margin: 1rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  position: relative;
`;

export const ListingImages = styled(Box)`
  .top-listing-image.covering {
    position: absolute;
    left: 0;
    transition: opacity 240ms ease-in-out;

    &:hover {
      opacity: 0;
    }
  }
`;

export const PhotoIcon = styled(IconButton)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: ${theme.palette.common.white};
  z-index: 1;
  box-shadow: ${theme.shadows[4]};

  .full-heart {
    display: none;
  }

  &.animate {
    top: -1rem;
    opacity: 0;
    transition: all 240ms ease-in-out;
  }

  &.favorite {
    opacity: 1;
    top: 1rem;
    left: 1rem;
  }

  &.favorite,
  &:hover {
    background: ${theme.palette.common.white};
    .empty-heart {
      display: none;
    }
    .full-heart {
      display: inline-block;
    }
  }
`;

export const ListingWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;

  .photo-icon {
    margin: 0.5rem;
  }

  &:hover {
    .photo-icon {
      top: 1rem;
      opacity: 1;
    }
  }
`;

export const ListingDetailWrapper = styled(Flex)`
  .main-image {
    border-radius: 5px;
    animation: fade-in 1s ease-in-out;
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const ImageContainer = styled(Flex)`
  margin-bottom: 0.5rem;
  opacity: 0.6;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &.selected {
    opacity: 1;
    border: ${IMAGE_CAROUSEL_ITEM_BORDER}px solid black;
    border-radius: 10px;
    img,
    video {
      width: ${IMAGE_CAROUSEL_ITEM_WIDTH - IMAGE_CAROUSEL_ITEM_BORDER * 2}px;
      height: ${IMAGE_CAROUSEL_ITEM_WIDTH - IMAGE_CAROUSEL_ITEM_BORDER * 2}px;
    }
  }
`;
