import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { send } from "@emailjs/browser";

import { MainLayout as Layout, TitleLayout } from "components/layout";
import { Flex } from "elements/common";
import { Form } from "elements/forms";
import { ContactUsForm } from "types/api";
import { ConfirmationType } from "types";
import {
  EMAIL_SERVICE_ID,
  EMAIL_TEMPLATE_ID,
  EMAIL_REGEX,
} from "constants/index";
import { theme } from "theme.main";

// const ReturnPolicy = () => <Flex marginTop="1rem">Return Policy</Flex>;
// const TrackingPackage = () => {
//   return <Flex marginTop="1rem">Tracking Package</Flex>;
// };

const SentEmailSuccess = ({ email }: { email: string }) => (
  <>
    Your email <b>({email})</b> has been sent!
  </>
);

const SentEmailError = () => (
  <>
    Something went wrong! Send email to <b>pravisbinding@gmail.com</b>
  </>
);

const ContactUs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<ConfirmationType>({});
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ContactUsForm>({
    defaultValues: {
      email: "", // TODO: once auth is set up, deafult value should be user's email
      message: "",
    },
  });

  const handleSendEmail = (email_params: ContactUsForm) => {
    setLoading(true);
    send(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, email_params)
      .then(() => {
        setConfirmation({
          open: true,
          message: <SentEmailSuccess email={getValues("email")} />,
          err: false,
        });
        reset();
      })
      .catch((err) => {
        // TODO: track alerts here
        setConfirmation({
          open: true,
          message: <SentEmailError />,
          err: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Flex marginTop="2rem" flexDirection="column">
      <Snackbar
        open={confirmation.open}
        sx={{
          width: "300px",
          textAlign: "left",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={confirmation.err ? 10000 : 5000}
        onClose={() => setConfirmation({ open: false })}
      >
        <Alert severity={confirmation.err ? "error" : "success"}>
          {confirmation.message}
        </Alert>
      </Snackbar>
      <Typography variant="h4">Contact Us</Typography>

      <Form onSubmit={handleSubmit(handleSendEmail)} fixedWidth>
        <FormControl error={Boolean(errors.email)} margin="normal">
          <InputLabel htmlFor="component-outlined">Email</InputLabel>
          <OutlinedInput
            id="component-outlined"
            label="Email"
            placeholder="Your email"
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
          {errors.email?.type === "required" && (
            <FormHelperText id="component-error-text">
              An email is required
            </FormHelperText>
          )}
          {errors.email?.type === "pattern" && (
            <FormHelperText id="component-error-text">
              Enter a valid email
            </FormHelperText>
          )}
        </FormControl>

        <FormControl error={Boolean(errors.message)} margin="normal">
          <InputLabel htmlFor="component-outlined">Message</InputLabel>
          <OutlinedInput
            id="component-outlined"
            label="Message"
            multiline
            rows={8}
            placeholder="Let me know what I can help with"
            {...register("message", { required: true })}
          />
          {errors.message && (
            <FormHelperText id="component-error-text">
              A message is required
            </FormHelperText>
          )}
        </FormControl>

        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="contained"
          size="large"
          startIcon={<SendIcon />}
          type="submit"
          sx={{
            borderRadius: "2rem",
            fontSize: "1.1em",
            borderWidth: "medium",
            marginTop: "1rem",
            width: "250px",
            alignSelf: "center",
          }}
        >
          Send Email
        </LoadingButton>
      </Form>
    </Flex>
  );
};

const Support = () => {
  return (
    <Layout bgColor={theme.palette.common.white}>
      <TitleLayout title="Support">
        <Flex justifyContent="center">
          <Flex flexDirection="column">
            {/* <ReturnPolicy />
          <TrackingPackage /> */}
            <ContactUs />
          </Flex>
        </Flex>
      </TitleLayout>
    </Layout>
  );
};

export default Support;
