import React, { useEffect, useState } from "react";

import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import _ from "lodash";

import { TOTAL_STARS } from "constants/index";
import { Flex } from "elements/common";
import { StarsDisplayProps } from "types";
import { ReviewProps } from "types/api";
import { theme } from "theme.main";

export const StarsDisplay = ({
  rating,
  spaceBelow = false,
}: StarsDisplayProps) => {
  const [fullStars, setFullStars] = useState<number>(0);
  const [halfStar, setHalfStar] = useState<boolean>(false);

  useEffect(() => {
    setFullStars(Math.floor(rating));
    setHalfStar(Boolean(rating % 1));
  }, [rating]);

  return (
    <Box color="#e9a13c" marginBottom={spaceBelow ? "2rem" : 0}>
      {_.times(Math.min(fullStars, TOTAL_STARS), (x) => (
        <StarIcon key={x} />
      ))}
      {halfStar && <StarHalfIcon />}
      {_.times(Math.floor(TOTAL_STARS - rating), (x) => (
        <StarOutlineIcon key={x} />
      ))}
    </Box>
  );
};

const Review = ({ rating, reviewText, reviewSrc }: ReviewProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Flex
          bgcolor={theme.palette.common.white}
          flexDirection="column"
          width="500px"
          padding="1rem"
          sx={{
            animation: "expand 250ms ease-in-out",
            "@keyframes expand": {
              from: { transform: "scale(0)" },
              to: { transform: "scale(1)" },
            },
          }}
        >
          <Flex justifyContent="space-between">
            <StarsDisplay rating={rating} spaceBelow />
            <Button
              onClick={() => setOpen(false)}
              sx={{ fontSize: "40px", height: "40px", borderRadius: "50px" }}
            >
              ×
            </Button>
          </Flex>
          <Typography marginBottom="2rem">{reviewText}</Typography>
          <Typography textAlign="right" color="gray" variant="body2">
            {reviewSrc}
          </Typography>
        </Flex>
      </Modal>
      <Paper
        onClick={() => setOpen(true)}
        elevation={9}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "180px",
          height: "180px",
          marginRight: "1rem",
          borderRadius: 0,
          textAlign: "left",
          padding: "1rem",
          cursor: "pointer",
          ":hover": { boxShadow: theme.shadows[2] },
        }}
      >
        <StarsDisplay rating={rating} />
        <Typography
          overflow="hidden"
          display="-webkit-box"
          sx={{
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical", // TODO: this is deprecated, but works
          }}
        >
          {reviewText}
        </Typography>
        <Typography textAlign="right" color="gray" variant="body2">
          {reviewSrc}
        </Typography>
      </Paper>
    </>
  );
};

export default Review;
