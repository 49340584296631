import { MainLayout, TitleLayout } from "components/layout";
import React from "react";

const PageNotFound = () => (
  <MainLayout>
    <TitleLayout title="404">Page not found</TitleLayout>
  </MainLayout>
);

export default PageNotFound;
