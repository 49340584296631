import React from "react";

import { Cloudinary } from "@cloudinary/url-gen";
import { auto, fill } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { AdvancedImage, AdvancedVideo } from "@cloudinary/react";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";

type ImageBuilderProps = {
  fileName: string;
  height?: number;
  width: number;
  resourceType: "image" | "video";
  className?: string;
  altText?: string;
  roundedEdges?: boolean;
  shouldAutoplay?: boolean;
};

/**
 * All assets (images and videos) are stored on Cloudinary.
 * Given the name of the file, the image is fetched, reformatted
 * and returned for us on site.
 *
 * All images are auto-cropped to square aspect ratios based on the
 * smaller length (width or height)
 *
 *
 * @param fileName name of file "book6_ksgjme"
 * @param height height of image/video
 * @param width width of image/video
 * @param resourceType "image" or "video" given by Cloudinary
 * @param className adding a classname to image/video
 * @param altText text to add as "alt" when asset isn't loaded
 * @param roundedEdges border display on media file
 * @param shouldAutoplay if true, video assets will autoplay on loop, otherwise, they'll be still
 *
 *
 * @returns
 */
const ImageBuilder = ({
  fileName,
  height,
  resourceType,
  width = 500,
  className,
  altText,
  roundedEdges,
  shouldAutoplay = true,
}: ImageBuilderProps) => {
  const cld = new Cloudinary({
    cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_NAME },
  });
  const isVideo = resourceType === "video";

  if (isVideo) {
    let vid = cld
      .video(fileName)
      .format("auto")
      .quality("auto")
      .resize(fill().width(width));

    if (height) vid.resize(auto().height(height));
    if (roundedEdges) vid.roundCorners(byRadius(8));

    return (
      <AdvancedVideo
        cldVid={vid}
        loop={shouldAutoplay}
        autoPlay={shouldAutoplay}
      />
    );
  }

  let img = cld
    .image(fileName)
    .format("auto")
    .quality("auto")
    .resize(auto().gravity(autoGravity()).width(width));
  if (height) img = img.resize(auto().height(height));
  if (roundedEdges) img.roundCorners(byRadius(8));

  return (
    <AdvancedImage
      className={className}
      alt={altText}
      loading="lazy"
      cldImg={img}
    />
  );
};

export default ImageBuilder;
