import React, { useState } from "react";

import { Link, MenuItem, Select, Typography } from "@mui/material";

import Badge from "components/badge";
import ButtonLink from "components/buttons/link";
import MediaWrapper from "components/image-builder/media-wrapper";
import { AnimatedPaper, Flex } from "elements/common";
import { theme } from "theme.main";
import { CartItemProps } from "types";
import { CARD_WIDTH } from "constants/index";

const CartItem = ({ item, idx, handleRemoveItem }: CartItemProps) => {
  const [count, setCount] = useState<number>(1);
  const [removeItem, setRemoveItem] = useState<boolean>(false);

  const handleMoveToWishlist = () => {
    handleRemoveItem(idx);
    setRemoveItem(true);
  };

  const handleDeleteFromCart = () => {
    handleRemoveItem(idx);
    setRemoveItem(true);
  };

  return (
    <AnimatedPaper
      className={removeItem ? " disappear" : ""}
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        marginBottom: "1rem",
        borderRadius: 0,
        padding: "2rem",
        height: ["450px", "250px"],
        "&:hover": { boxShadow: theme.shadows[2] },
      }}
      elevation={5}
    >
      <MediaWrapper
        title={item.title}
        primaryMedia={item.mediaList[0]}
        secondaryMedia={item.mediaList[1]}
        sx={{ maxWidth: CARD_WIDTH, img: { width: "100%", height: "auto" } }}
      />
      <Flex
        flexDirection="column"
        marginLeft={[0, "3rem"]}
        height="100%"
        justifyContent="space-between"
        minWidth="202px"
      >
        <Flex flexDirection="column" alignItems="flex-start">
          <Link
            underline="none"
            variant="h5"
            target="_blank"
            rel="noopener, noreferrer"
            href={`/shop/${item.key}`}
            color={theme.palette.common.black}
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {item.title}
          </Link>
          <Typography>${item.price}</Typography>
          {item.discount && <Badge text={item.discount} />}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={count}
            size="small"
            onChange={(e) => setCount(e.target.value as number)}
            sx={{ marginTop: ["2rem", "3rem"], width: "100px" }}
          >
            {/* TODO: have max availabe */}
            {[1, 2, 3, 4, 5].map((num) => (
              <MenuItem value={num} key={`amount-${num}`}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </Flex>
        <Flex justifyContent="space-between" alignSelf={["auto", "flex-end"]}>
          <ButtonLink
            handleOnClick={handleMoveToWishlist}
            text="Move to Wishlist"
            underline
          />
          <ButtonLink
            handleOnClick={handleDeleteFromCart}
            text="Delete"
            underline
          />
        </Flex>
      </Flex>
    </AnimatedPaper>
  );
};

export default CartItem;
