const CARD_HEIGHT = 250;
const CARD_WIDTH = 250;

const MIN_PRICE_RANGE = 10;
const MIN_PRICE = 0;
const MAX_PRICE = 150;

const TOTAL_STARS = 5;

const VIDEO_FILE_FORMATS = ["mov", "mp4", "avi", "wmv", "avchd", "flv", "gif"];
const IMAGE_FILE_FORMATS = ["jpeg", "jpg", "png", "svg", "tiff", "bmp"];

const IMAGE_CAROUSEL_ITEM_WIDTH = 75;
const IMAGE_CAROUSEL_ITEM_BORDER = 3;

const EMAIL_SERVICE_ID = "service_172bjlr";
const EMAIL_TEMPLATE_ID = "template_33u404w";
const EMAIL_REGEX =
  /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i; //eslint-disable-line

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/i;
const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/i;

const FOOTER_HEIGHT = "80px";
const HEADER_HEIGHT = "64px";

const GOODREADS_QUOTES_SCRIPT =
  "https://www.goodreads.com/quotes/widget/121212457-prav-tadikonda?v=2";

const US_STATES = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Minor Outlying Islands",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "U.S. Virgin Islands",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export {
  // listing cards
  CARD_HEIGHT,
  CARD_WIDTH,

  // filters
  MIN_PRICE_RANGE,
  MIN_PRICE,
  MAX_PRICE,

  // media
  VIDEO_FILE_FORMATS,
  IMAGE_FILE_FORMATS,

  // listing detail images
  IMAGE_CAROUSEL_ITEM_WIDTH,
  IMAGE_CAROUSEL_ITEM_BORDER,

  // email - support & login
  EMAIL_SERVICE_ID,
  EMAIL_TEMPLATE_ID,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  ZIPCODE_REGEX,

  // header & footer for styling
  FOOTER_HEIGHT,
  HEADER_HEIGHT,

  // widgets
  GOODREADS_QUOTES_SCRIPT,

  // form dropdown states
  US_STATES,

  // reviews
  TOTAL_STARS,
};
