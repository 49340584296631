import styled from "@emotion/styled";
import { Flex } from "./common";

export const NavigatePosts = styled(Flex)`
  .previous-post {
    margin-right: 1rem;
  }
  .next-post {
    margin-left: 1rem;
  }
`;
