import React from "react";

import { Typography } from "@mui/material";

import { LayoutContainer } from "elements/layout";
import { TitleLayoutType } from "types";

/**
 * Page with title
 *
 * @param title: Title of page
 */
const TitleLayout = ({
  title,
  fullPage = false,
  color,
  children,
}: TitleLayoutType) => (
  <LayoutContainer className={`${fullPage ? "full-page" : ""}`}>
    <Typography
      color={color}
      variant="h2"
      className="title"
      fontFamily="Blackjack, cursive"
    >
      {title}
    </Typography>
    {children}
  </LayoutContainer>
);

export default TitleLayout;
