import { Review } from "components/cards";
import { Flex, Grid } from "elements/common";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { fakeReviews } from "tests/fake-returns";
import { theme } from "theme.main";

const ReviewBar = () => {
  const [overflowFade, setOverflowFade] = useState(false);

  useEffect(() => {
    fakeReviews.length > 4 ? setOverflowFade(true) : setOverflowFade(false);
  }, []);

  const handleScroll = (e: BaseSyntheticEvent) => {
    const scrollPos = e.target.scrollLeft + e.target.offsetWidth + 16;
    const totalWidth = e.target.scrollWidth;

    totalWidth <= scrollPos ? setOverflowFade(false) : setOverflowFade(true);
  };

  return (
    <Flex
      bgcolor={theme.palette.secondary.light}
      padding="2rem"
      justifyContent="center"
    >
      <Grid
        maxWidth={theme.breakpoints.values.lg}
        overflow="scroll"
        gridAutoFlow="column"
        alignItems="center"
        height="250px"
        sx={{
          WebkitMaskImage: overflowFade
            ? "linear-gradient(90deg, #808b7e 95%, transparent)"
            : "none",
        }}
        onScroll={handleScroll}
      >
        {fakeReviews.map((review, idx) => (
          <Review
            key={idx}
            rating={review.rating}
            reviewText={review.reviewText}
            reviewSrc={review.reviewSrc}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default ReviewBar;
