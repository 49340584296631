import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Tooltip,
  tooltipClasses,
  Typography,
  Zoom,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ButtonLink from "components/buttons/link";
import CollapsedInfo from "components/collapse";
import { MainLayout as Layout, TitleLayout } from "components/layout";
import { EMAIL_REGEX } from "constants/index";
import { Flex } from "elements/common";
import { Form } from "elements/forms";
import { CheckoutFormValues, OrderSummaryHeaderProps } from "types";
import { theme } from "theme.main";
import PaymentOptions from "./payment-options";
import ShippingDetails from "./shipping-info";
import CartItem from "./cart-item";
import EmptyCartIcon from "assets/empty-cart";

import { fakeListings } from "tests/fake-returns";

const OrderSummaryHeader = ({
  errors,
  register,
  handleFormSubmit,
}: OrderSummaryHeaderProps) => {
  const navigate = useNavigate();
  const loggedIn = false; // TODO: determine if user is logged in

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h5" textAlign="left">
          Order Summary
        </Typography>
        {!loggedIn && (
          <ButtonLink
            text="Sign In"
            handleOnClick={() => navigate("/login/")}
            sx={{ paddingRight: 0 }}
          />
        )}
      </Flex>
      {!loggedIn && (
        <>
          <Typography
            color={theme.palette.grey[700]}
            variant="body2"
            textTransform="uppercase"
            fontStyle="italic"
            textAlign="left"
          >
            Guest Checkout
          </Typography>
          <Form
            style={{ maxWidth: "none", width: "100%" }}
            onSubmit={handleFormSubmit}
            fixedWidth
          >
            <FormControl
              error={Boolean(errors.email)}
              margin="normal"
              size="small"
            >
              <InputLabel htmlFor="component-outlined">Email</InputLabel>
              <OutlinedInput
                id="component-outlined"
                label="Email"
                placeholder="Receive info on order"
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEX,
                })}
              />
              {errors.email?.type === "required" && (
                <FormHelperText id="component-error-text">
                  An email is required
                </FormHelperText>
              )}
              {errors.email?.type === "pattern" && (
                <FormHelperText id="component-error-text">
                  Enter a valid email
                </FormHelperText>
              )}
            </FormControl>
          </Form>
        </>
      )}
    </>
  );
};

const OrderItemized = () => {
  return (
    <Flex flexDirection="column" marginTop="1rem">
      {[
        { title: "Subtotal", amount: "$15.00" },
        { title: "Discount", amount: "-$20.00" },
        { title: "Shipping Costs", amount: "$20.00" },
        { title: "Tax", amount: "$2.00" },
      ].map(({ title, amount }) => (
        <Flex key={title} alignItems="center" justifyContent="space-between">
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body2">{amount}</Typography>
        </Flex>
      ))}
    </Flex>
  );
};

const GiftOptions = () => {
  return (
    <CollapsedInfo
      header="Gift Options"
      openText="Edit"
      closeText="Save"
      headerContent={
        <Typography color={theme.palette.grey[700]} variant="body2">
          Not a gift
        </Typography>
      }
    >
      <Flex padding="1rem">
        A bunch of radio buttons - special note? wrapped? is it a gift?
      </Flex>
    </CollapsedInfo>
  );
};

const EmptyCart = () => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        display: "flex",
        padding: "3rem 2rem",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: [theme.shadows[0], theme.shadows[0], theme.shadows[3]],
        bgcolor: [
          theme.palette.secondary.light,
          theme.palette.secondary.light,
          theme.palette.common.white,
        ],
      }}
    >
      <Typography
        variant="h3"
        color={theme.palette.secondary.main}
        fontWeight={500}
        marginBottom="2rem"
      >
        Cart is Empty!
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginBottom: "4rem", fontSize: "1rem" }}
        onClick={() => navigate("/shop/")}
      >
        Continue Shopping
      </Button>

      <EmptyCartIcon width={375} height={288} />
    </Paper>
  );
};

const Cart = () => {
  const [cartItems, setCartItems] = useState(fakeListings.slice(0, 4));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckoutFormValues>({
    defaultValues: {
      email: "",
    },
  });

  const removeItem = (id: number) => {
    setTimeout(() => {
      const items_copy = [...cartItems];
      items_copy.splice(id, 1);
      setCartItems(items_copy);
    }, 300);
  };

  const handlePurchaseOrder = () => {};

  const allowedToPlaceOrder = false; // TODO: based on form input

  return (
    <Layout bgColor={theme.palette.secondary.light}>
      <TitleLayout title="Checkout">
        <Flex
          margin={[0, 0, "1rem 0"]}
          justifyContent="center"
          flexDirection={["column-reverse", "column-reverse", "row"]}
        >
          <Flex
            flexDirection="column"
            marginRight={[0, 0, "2rem"]}
            marginTop={["2rem", "2rem", 0]}
            alignItems="center"
          >
            {cartItems.map((listing, idx) => (
              <CartItem
                key={listing.key}
                item={listing}
                idx={idx}
                handleRemoveItem={removeItem}
              />
            ))}
          </Flex>
          {cartItems.length ? (
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "2rem",
                width: ["100%", "350px"],
                height: "fit-content",
                alignSelf: ["center", "center", "flex-start"],
                maxWidth: "calc(350px + 4rem)",
                boxSizing: ["border-box", "content-box"],
              }}
            >
              <OrderSummaryHeader
                register={register}
                errors={errors}
                handleFormSubmit={handleSubmit(handlePurchaseOrder)}
              />
              <Divider
                sx={{ width: "100%", alignSelf: "center", marginTop: "1rem" }}
              />
              <OrderItemized />
              <Divider
                sx={{ width: "100%", alignSelf: "center", marginTop: "1rem" }}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                marginTop="1rem"
              >
                <Typography fontWeight={600}>Total</Typography>
                <Box
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color={theme.palette.primary.dark}
                >
                  $18.00
                </Box>
              </Flex>

              <Divider
                sx={{ width: "100%", alignSelf: "center", marginTop: "1rem" }}
              />
              <ShippingDetails />
              <PaymentOptions />
              <GiftOptions />

              <Tooltip
                title="You must add in shipping & payment info"
                arrow
                TransitionComponent={Zoom}
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}`]: {
                        display: allowedToPlaceOrder ? "none" : "block",
                      },
                    },
                  },
                }}
              >
                <span>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubmit(handlePurchaseOrder)}
                    sx={{
                      marginTop: "2rem",
                      borderRadius: "20px",
                      width: "100%",
                    }}
                    disabled={!allowedToPlaceOrder}
                  >
                    Place Order
                  </LoadingButton>
                </span>
              </Tooltip>
            </Paper>
          ) : (
            <EmptyCart />
          )}
        </Flex>
      </TitleLayout>
    </Layout>
  );
};

export default Cart;
